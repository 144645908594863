import React, { useEffect } from "react";
const NotFound = () => {
  useEffect(() => {
    document.documentElement.classList.remove("headerDeactivate");
  }, []);
  return (
    <div className="commonBody">
      <div className="notFound">
        <p className="mainHeading mt-3">Oops! page is not found</p>
        <p className="mainHeadingPara">
          The page you are looking for doesn't exist or an other error occurred.
        </p>
      </div>
    </div>
  );
};

export default NotFound;
