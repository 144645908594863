import { createUseStyles } from "react-jss";
import breakpoints from "assets/breakpoints";
export const useStyles = createUseStyles({
  body: {
    // paddingTop: "22px",
    [breakpoints.SCREEN_LG_MIN]: {
      paddingBottom: "22px",
      minHeight: "100%",
      height: "100%",
    },
    "& .bContainer": {
      paddingTop: "22px",
      "&.sChain": {
        [breakpoints.SCREEN_LG_MAX]: {
          maxWidth: "576px",
        },
      },
    },
    "& .bContainer, & .bRow": {
      [breakpoints.SCREEN_LG_MIN]: {
        minHeight: "calc(100vh - 162px)",
      },
    },
  },
});
