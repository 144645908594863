import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  coinList: {
    gap: "6px",
    "& .colItems": {
      backgroundColor: "#F2F4F8",
      borderRadius: "10px",
      flex: "0 0 48.8%",
      maxWidth: "170px",
      padding: "10px",
      "&.active": {
        backgroundColor: "#404eb7",
        "& .coName": {
          "& > p": {
            color: "var(--white)",
          },
          "& > span": {
            color: "var(--white)",
          },
        },
      },
      "& .coIcn": {
        width: "28px",
        height: "28px",
        backgroundColor: "var(--white)",
        borderRadius: "50rem",
        marginRight: "8px",
        "& svg": {
          width: "24px",
          height: "24px",
        },
      },
      "& .coName": {
        flex: "1",
        overflow: "hidden",
        maxWidth: "calc(100% - 1px)",
        textOverflow: "ellipsis",
        "& > p": {
          fontSize: "15px",
          lineHeight: "1",
          paddingBottom: "5px",
          color: "var(--dark)",
        },
        "& > span": {
          fontSize: "13px",
          lineHeight: "1",
          color: "var(--dark)",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          verticalAlign: "middle",
        },
      },
    },
  },
  coinSkeleton: {
    gap: "6px",
    "& .ant-skeleton": {
      flex: "0 0 48.8%",
      maxWidth: "170px",
      "& .ant-skeleton-input": {
        height: "53px",
        width: "100%",
        minWidth: "100%",
        borderRadius: "10px",
      },
    },
  },
});
