import { createUseStyles } from "react-jss";
import breakpoints from "assets/breakpoints";
export const useStyles = createUseStyles({
  merCard: {
    borderRadius: "10px",
    border: "1px solid #f1f1f4",
    backgroundColor: "var(--white)",
    boxShadow: "rgb(140 149 159 / 2%) 0px 8px 24px 0px",
    [breakpoints.SCREEN_MD_MIN]: {
      padding: "18px",
    },
    [breakpoints.SCREEN_MD_MAX]: {
      padding: "15px",
    },
  },
  lineList: {
    "& .llItems": {
      "&:not(:last-child)": {
        marginBottom: "14px",
        paddingBottom: "14px",
        borderBottom: "1px solid #f4f4f4",
      },
    },
  },
});
