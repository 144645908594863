import { createUseStyles } from "react-jss";
import selectIcnSvg from "assets/images/Icn/selectIcn.svg";
import SearchSVGIcn from "assets/images/Icn/searchIcn.svg";
export const useformCommons = createUseStyles({
  mandatory: {},
  formGrp: {
    position: "relative",
    marginBottom: "0.625rem",
    "&.searchIcn": {
      position: "relative",
      "&:before": {
        content: '""',
        width: "40px",
        height: "40px",
        backgroundImage: `url(${SearchSVGIcn})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        position: "absolute",
        top: "0",
        right: "0",
      },
      "& > input": {
        paddingRight: "40px",
      },
    },
    // select
    "& select": {
      backgroundImage: `url(${selectIcnSvg}) !important`,
      backgroundPosition: "center right 5px",
      paddingRight: "30px !important",
      backgroundRepeat: "no-repeat",
      backgroundSize: "auto",
    },
  },
  formControl: {
    display: "block",
    width: "100%",
    fontFamily: "var(--font-nunito)",
    minHeight: "calc(0.8em + 1.65rem + 2px)",
    padding: "0.401rem 10px",
    fontSize: ".875rem",
    fontWeight: "var(--fontWeight600)",
    lineHeight: "1.8",
    color: "var(--dark)",
    backgroundColor: "var(--white)",
    backgroundClip: "padding-box",
    WebkitAppearance: "none",
    MozAppearance: "none",
    appearance: "none",
    border: "1px solid var(--whitesmoke)",
    borderRadius: "8px",
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    // focus
    "&:focus": {
      backgroundColor: "var(--white)",
      outline: "0",
      boxShadow: "0 0 1rem 0 rgb(140 152 164 / 25%)",
    },
    // /focus
    // ant-select
    "&.ant-select-open": {
      "& .ant-select-selector": {
        color: "var(--dark)",
        backgroundColor: "var(--white)",
        outline: "0",
        boxShadow: "0 0 1rem 0 rgb(140 152 164 / 25%)",
      },
    },
    "&.ant-select-focused": {
      "& .ant-select-selector": {
        color: "var(--darkMore)",
        backgroundColor: "var(--white)",
        outline: "0",
        boxShadow: "0 0 1rem 0 rgb(140 152 164 / 25%)",
        "& .ant-select-selection-overflow": {
          "& .ant-select-selection-search": {
            top: "2px",
          },
        },
      },
    },
    "& .ant-select-selector": {
      backgroundColor: "transparent !important",
      border: "1px solid var(--whitesmoke) !important",
      "&:focus": {
        color: "var(--darkMore)",
        backgroundColor: "var(--white)",
        outline: "0",
        boxShadow: "0 0 1rem 0 rgb(140 152 164 / 25%)",
      },
      "& .ant-select-selection-item": {
        color: "var(--darkMore)",
        "& .ant-select-selection-overflow": {
          "& .ant-select-selection-search": {
            marginInlineStart: "2px",
            "& input": {
              color: "var(--darkMore)",
            },
          },
        },
      },
      "& .ant-select-selection-overflow-item": {
        "& .ant-select-selection-item": {
          alignItems: "center",
          backgroundColor: "var(--themeLightFi) !important",
          border: "0 !important",
          borderRadius: "5px !important",
          paddingInlineStart: "6px",
          "& .ant-select-selection-item-content": {
            fontSize: "13px",
            fontWeight: "var(--fontWeight600)",
            lineHeight: "26px",
            marginInline: "6px",
            position: "relative",
            order: "2",
          },
          "& .ant-select-selection-item-remove": {
            order: "1",
            width: "11px",
            height: "13px",
            flex: "0 0 11px",
            overflow: "unset",
            "& .anticon.anticon-close": {
              verticalAlign: "3px",
              "& svg": {
                width: "11px",
                height: "11px",
                position: "relative",
                top: "2px",
                "& > path": {
                  fill: "var(--dark)",
                },
              },
            },
          },
        },
      },
    },
    // /ant-select
  },
});
