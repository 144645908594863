import ProductComponent from "components/Product/ProductComponent";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import { BasketProvider } from "./constant";
import HeaderComponent from "components/Layout/HeaderComponent";

function ProductContainer(props: any) {
  useEffect(() => {
    document.documentElement.classList.remove("headerDeactivate");
  }, []);
  return (
    <BasketProvider>
      <Helmet>
        <title>POS | PocketPay</title>
        <meta name="description" content="MTC" />
      </Helmet>
      <HeaderComponent />
      <div className="container bContainer">
        <ProductComponent />
      </div>
    </BasketProvider>
  );
}

export default ProductContainer;
