import classNames from "classnames";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import ProductContainer from "containers/ProductContainer";
import CheckoutContainer from "containers/CheckoutContainer";
import ExternalPayContainer from "containers/ExternalPayContainer";
import NotFound from "components/NotFound";
import { useStyles } from "components/Layout/BodyComponent/style";

const BodyComponent = () => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.body, "bodyCommon")}>
      <Router>
        <Switch>
          <Route path="/pos/:id" component={ProductContainer} />
          <Route path="/checkout/:id" component={CheckoutContainer} />
          <Route path="/:id" component={ExternalPayContainer} />
          <Route path="/" component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
};

export default BodyComponent;
