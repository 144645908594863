import React, { useEffect, useState } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { parseEther } from "viem";
import {
  useAccount,
  useSwitchChain,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import {
  polygonMumbai,
  bscTestnet,
  baseSepolia,
  taikoKatla,
  lineaSepolia,
  blastSepolia,
} from "wagmi/chains";
import { selectedChainKey, toastMessage } from "utils/helper";
import { abi } from "./abi";
import ButtonComponent from "components/Forms/ButtonComponent";

type BlockchainKey = "Blast" | "BNB" | "Polygon" | "BASE" | "LINEA" | "TAIKO";
interface ExtendedError extends Error {
  shortMessage?: string;
}

const BlastWalletConnect = ({
  setLoading,
  onPaymentSuccess,
  generateJsonContent,
}: any) => {
  const wallet = useAccount();
  const { chains, switchChain } = useSwitchChain();
  const [btnLoader, setBtnLoader] = useState(false);
  const [isCorrectChainKey, setIsCorrectChainKey] = useState(false);
  const { data: hash, error, isPending, writeContract } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isSuccessed } =
    useWaitForTransactionReceipt({
      hash,
    });

  const blockchainChainIds: Record<BlockchainKey, number> = {
    Blast: blastSepolia.id,
    BNB: bscTestnet.id,
    Polygon: polygonMumbai.id,
    BASE: baseSepolia.id,
    LINEA: lineaSepolia.id,
    TAIKO: taikoKatla.id,
  };

  const blockchainAddresses: Record<BlockchainKey, string> = {
    Blast: "0x36eA503F0adc6D476eD659e4348bc34ab6755582",
    BNB: "0x00c1B6580c714366Fdb4e7B946Be99123d2d0b13",
    Polygon: "0x40d8c3eb081f5d57a63bb9f2501e36a9334603b5",
    BASE: "0x40d8c3Eb081F5D57a63Bb9F2501E36A9334603B5",
    LINEA: "0x30084a2322A2539755a100cAb8fd0794e58348E0",
    TAIKO: "0x40d8c3Eb081F5D57a63Bb9F2501E36A9334603B5",
  };

  const contractAddress = blockchainAddresses[
    generateJsonContent.blockchain as BlockchainKey
  ] as `0x${string}`;
  const desiredChainId =
    blockchainChainIds[generateJsonContent.blockchain as BlockchainKey];

  const txRun = async () => {
    setBtnLoader(true);
    if (wallet.chainId !== desiredChainId) {
      try {
        await switchChain({ chainId: desiredChainId });
        setBtnLoader(false);
      } catch (switchError) {
        setBtnLoader(false);
        toastMessage("Failed to switch networks:");
        return;
      }
    } else {
      try {
        const run = await writeContract({
          abi,
          address: contractAddress,
          functionName: "transaction",
          args: [
            generateJsonContent.merchant_name,
            BigInt(generateJsonContent.order_id),
            generateJsonContent.merchant_address,
          ],
          value: parseEther(generateJsonContent.amount.toString()),
        });
      } catch (error: any) {
        setBtnLoader(false);
        toastMessage(error && error.message)
      }
    }
  };

  useEffect(() => {
    const extendedError = error as ExtendedError;
    if (extendedError !== null && extendedError?.shortMessage) {
      setBtnLoader(false);
      toastMessage(extendedError.shortMessage);
    }
  }, [error]);

  useEffect(() => {
    const getUniqueIdentify = selectedChainKey(generateJsonContent.blockchain);
    wallet && getUniqueIdentify && wallet.chainId === getUniqueIdentify
      ? setIsCorrectChainKey(true)
      : setIsCorrectChainKey(false);
  }, [wallet]);

  useEffect(() => {
    if (
      wallet &&
      wallet.address !== undefined &&
      hash !== undefined &&
      isSuccessed === true
    ) {
      onPaymentSuccess(
        generateJsonContent,
        hash,
        wallet.address,
        generateJsonContent.order_id
      );
    }
  }, [isSuccessed]);

  return (
    <>
      <div className="walletConnect text-center py-4 px-2 px-md-5 w-100">
        <ConnectButton />
        {wallet.isConnected && (
          <div className="pt-4">
            <ButtonComponent
              title="Pay Now"
              btnPrimary
              onClick={() => !btnLoader && txRun()}
              extraClass="chainKey w-100"
              style={{ maxWidth: "260px" }}
            >
              {btnLoader
                ? "Loading..."
                : isCorrectChainKey
                  ? "Pay Now"
                  : "Switch Network"}
            </ButtonComponent>
          </div>
        )}
      </div>
    </>
  );
};

export default BlastWalletConnect;
