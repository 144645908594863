import React from "react";
import { Skeleton } from "antd";
import { useStyles } from "components/EmptyComponent/style";

const EmptyComponent = ({
  active,
  size,
  title,
  image,
  htmlFor,
  className,
  extraClass,
  style,
  ...rest
}: any) => {
  const classes = useStyles();
  return (
    <>
      <Skeleton.Input className={className} style={style} />
    </>
  );
};

export default EmptyComponent;
