import React from "react";
import { Image, Tooltip } from "antd";
import { useStyles } from "components/Product/Category/CategoryComponent/style";
import classNames from "classnames";
import { baseImageURL } from "utils/env";
import MonogramIcon from "assets/images/mongram.svg";

const CategoryComponent = ({
  viewName,
  categoryList,
  setCatClick,
  catClick,
  setNewOne,
}: any) => {
  const classes = useStyles();
  return (
    <>
      {viewName === "pos" ? (
        <div className={classNames(classes.catTNav, "d-flex gap-10 mb-4")}>
          {/* <div className="catTItems"> */}
          {categoryList.map((item: any, index: any) => {
            return (
              <Tooltip
                key={item.category_id}
                placement="top"
                className="cursorPointer"
                title={item.category_name}
              >
                <div
                  className={
                    catClick.category_id === item.category_id
                      ? "catTItems text-center bg-white active"
                      : "catTItems text-center bg-white"
                  }
                  key={index}
                  onClick={() => {
                    setCatClick(item);
                    setNewOne(1);
                  }}
                >
                  <div className="catTIImg ms-auto me-auto">
                    {item.image_path !== null ? (
                      <Image
                        className="ciiImg"
                        src={`${baseImageURL}${item.image_path}`}
                        alt="Category image"
                        preview={false}
                      />
                    ) : (
                      <Image
                        width={38}
                        className="ciiLogo"
                        src={MonogramIcon}
                        alt="Monogram"
                        preview={false}
                      />
                    )}
                  </div>
                  <div className="catTITitle text-dark fontWeight600">
                    <p className="m-0 fontWeight700 text-dark">
                      {item.category_name}
                    </p>
                    <small className="d-block">
                      {item.total_num_of_product} products
                    </small>
                  </div>
                </div>
              </Tooltip>
            );
          })}
          {/* </div> */}
        </div>
      ) : (
        <></>
        // <div className="ecTabs">
        //   <div className="ecTabsInner">
        //     {categoryList.map((item: any, index: any) => {
        //       return (
        //         <div
        //           className={
        //             catClick.category_id === item.category_id
        //               ? "active categoryTabs"
        //               : "categoryTabs"
        //           }
        //           key={index}
        //           onClick={() => {
        //             setCatClick(item);
        //             setNewOne(1);
        //           }}
        //         >
        //           <p className="subInnerHeading">{item.category_name}</p>
        //         </div>
        //       );
        //     })}
        //   </div>
        // </div>
      )}
    </>
  );
};

export default CategoryComponent;
