export const CreatePaylink = "create_paylinks";
export const MerchantProduct = "merchant_product";
export const MerchantCategory = "get_categories";
export const MerchantSetting = "merchant_settings";
export const MerchantAddress = "merchant_address";
export const MerchantOrders = "merchant_orders";
export const Ecommerce = "merchant-ecommerce";
export const MerchantPosSettings = "merchant_pos_settings";
export const GetSingleOrder = "get-single-order";
export const GetOrdersProducts = "merchant_orders_products_with";
export const ThirdInItUrl = "payment-init";
export const Login = "user/login";
