const isEmpty = (value) =>
  value === undefined || value === null || value === "";

export const emailValidationExp = new RegExp(
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
);

export function Email(value) {
  // Let's not start a debate on email regex. This is just for an example app!

  // eslint-disable-next-line no-use-before-define
  if (!isEmpty(value) && !emailValidationExp.test(value)) {
    // eslint-disable-line
    return !isEmpty(value) && !emailValidationExp.test(value); // eslint-disable-line
  }
  return false;
}
