import { WalletKitProvider } from "@mysten/wallet-kit";
import { AptosWalletAdapterProvider } from "@aptos-labs/wallet-adapter-react";
import { PetraWallet } from "petra-plugin-wallet-adapter";
import { MartianWallet } from "@martianwallet/aptos-wallet-adapter";
import "@aptos-labs/wallet-adapter-ant-design/dist/index.css";
import FooterComponent from "components/Layout/FooterComponent";
import BodyComponent from "components/Layout/BodyComponent";
import { GlobalDisplayProvider, GlobalMerchantProvider } from "utils/helper";
import "./assets/common.css";
import classNames from "classnames";
import { useStyles } from "./style";

const wallets = [new PetraWallet(), new MartianWallet()];
function App() {
  const classes = useStyles();
  localStorage.setItem("userTheme", "light");
  return (
    <AptosWalletAdapterProvider plugins={wallets} autoConnect={true}>
      <WalletKitProvider
        features={["sui:signTransactionBlock"]}
        enableUnsafeBurner
      >
        <div className={classNames(classes.main, "mainCommon")}>
          <GlobalMerchantProvider>
            <GlobalDisplayProvider>
              <BodyComponent />
              <FooterComponent />
            </GlobalDisplayProvider>
          </GlobalMerchantProvider>
        </div>
      </WalletKitProvider>
    </AptosWalletAdapterProvider>
  );
}

export default App;
