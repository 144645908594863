/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { useStyles } from "components/BasketComponent/style";
import classNames from "classnames";
import { Button, Image } from "antd";
import axios from "axios";
import { useBasket } from "containers/ProductContainer/constant";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { baseImageURL, baseURL } from "utils/env";
import MonogramIcon from "assets/images/mongram.svg";
import {
  encodeContent,
  shortenValue,
  useGlobalDisplay,
  useGlobalMerchant,
} from "utils/helper";
import { EmptyCartIcon } from "svgIcon";
import ButtonComponent from "components/Forms/ButtonComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faK,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { LoadingOutlined } from "@ant-design/icons";

const BasketComponent = ({ showSideBasket }: any) => {
  const { itemList, addToBasket, removeFromItem, removeFromBasket } =
    useBasket();
  const classes = useStyles();
  const history = useHistory();
  const { globalMerchantId } = useGlobalMerchant();
  const { setGlobalDisplayId, globalDisplayId } = useGlobalDisplay();
  const [merchantId, setMerchantId] = useState("");
  const [titleName, setTitleName] = useState("");
  const [onBtnLoader, setOnBtnLoader] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const totalAmounts = itemList.map((item: any) => {
    return item.qty * item.product.price;
  });

  const grandTotal = totalAmounts.reduce(
    (acc: any, amount: any) => acc + amount,
    0
  );

  const onCheckout = () => {
    if (grandTotal !== 0) {
      setOnBtnLoader(true);
      const modifyResp = itemList.map((item: any) => {
        return {
          categories: item.product.categories,
          img: item.product.img,
          price: item.product.price,
          product_id: item.product.product_id,
          name: item.product.product_name,
          sku: item.product.sku,
        };
      });
      const getWalletEmail = localStorage.getItem("ec-email");
      const newObject = {
        merchant_id: merchantId,
        amount: shortenValue(grandTotal),
        name: " ",
        client_address: " ",
        date_time: " ",
        digest: " ",
        product_detail: modifyResp,
        status: "pending",
        merchant_email: getWalletEmail,
        customer_email: " ",
        order_type: "POS",
      };
      try {
        axios
          .post(`${baseURL}merchant_orders`, newObject)
          .then((response: any) => {
            if (response && response.data !== null && response.data.order_id) {
              if (titleName === "pos") {
                const generateKey = encodeContent(
                  `pos=${response.data.order_id}`
                );
                history.push(`/checkout/${generateKey}`);
              } else {
                const generateKey = encodeContent(
                  `ecommerce=${response.data.order_id}`
                );
                history.push(`/checkout/${generateKey}`);
              }
            }
          });
      } catch (error: any) {}
    }
  };

  const handlePlusAction = (productId: string, product: any) => {
    addToBasket(product);
  };

  const handleMinusAction = (productId: string, product: any) => {
    itemList.map((item: any) => {
      if (item.product.product_id === productId) {
        if (item.qty > 1) {
          removeFromItem(productId);
        } else {
          removeFromBasket(productId);
        }
      }
    });
  };

  useEffect(() => {
    if (globalMerchantId !== null && globalMerchantId !== undefined) {
      setMerchantId(globalMerchantId);
      setTitleName("pos");
    }
  }, [globalMerchantId]);
  return (
    <>
      <h4 className="mriTitle m-0 fontWeight700 text-dark d-flex align-items-center justify-content-between">
        Basket
        <FontAwesomeIcon
          style={{ width: "22px", height: "22px" }}
          className={screenSize ? "cursorPointer d-block" : "d-none"}
          icon={faClose}
          onClick={() => setGlobalDisplayId(false)}
        />
      </h4>
      <div className="mriBody d-flex flex-column">
        <div className="mribTo">
          {grandTotal === 0 ? (
            <div
              className={classNames(classes.emptyBasket, "my-5 text-center")}
            >
              <EmptyCartIcon />
              <h4 className="text-dark fontWeight700 m-0">
                Your basket is currently empty
              </h4>
              <p className="text-dark fontWeight600 opacity50">
                Before proceed to checkout you must add some products to your
                shopping cart. You will find a lot of interesting products on
                your '<strong>Categories</strong>' page.
              </p>
            </div>
          ) : (
            <>
              <div className={classNames(classes.basketCart)}>
                {itemList.map((item: any) => (
                  <div
                    key={item.product.product_id}
                    className="basCItems d-flex align-items-center justify-content-between"
                  >
                    <div className="bciLe d-flex align-items-center">
                      <div className="bciLImg">
                        {item.product.img !== null ? (
                          <Image
                            width={50}
                            src={`${baseImageURL}${item.product.img}`}
                            alt="Product image"
                            preview={false}
                          />
                        ) : (
                          <Image
                            width={50}
                            src={MonogramIcon}
                            alt="Product image"
                            preview={false}
                          />
                        )}
                      </div>
                      <div className="bciLText d-flex flex-column">
                        <small className="text-dark">
                          {item.product.product_name}
                        </small>
                        <span className="fontWeight800 text-dark">
                          {shortenValue(item.qty * item.product.price)} USD
                        </span>
                      </div>
                    </div>
                    <div className="bciRi d-flex align-items-center">
                      <Button
                        className="bciMinus d-inline-flex align-items-center justify-content-center p-0"
                        disabled={item.qty === 1 && true}
                        onClick={() =>
                          item.qty !== 1 &&
                          handleMinusAction(
                            item.product.product_id,
                            item.product
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </Button>
                      <span className="bciCount fontWeight700 d-inline-flex mx-2">
                        {item.qty}
                      </span>
                      <Button
                        className="bciPlus d-inline-flex align-items-center justify-content-center p-0"
                        onClick={() =>
                          handlePlusAction(
                            item.product.product_id,
                            item.product
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="mribBo mt-auto pt-3">
          {grandTotal !== 0 && (
            <div className={classNames(classes.payCard, "mb-3")}>
              <div className="paycItems d-flex align-items-center justify-content-between">
                <small className="text-dark fontWeight500">Subtotal</small>
                <span className="text-dark fontWeight700">
                  {shortenValue(grandTotal)} USD
                </span>
              </div>
              <div className="paycItems d-flex align-items-center justify-content-between">
                <small className="text-dark fontWeight500">Discount</small>
                <span className="text-dark fontWeight700">00.00 USD</span>
              </div>
              <div className="paycItems d-flex align-items-center justify-content-between">
                <small className="text-dark fontWeight500"> Tax(12%)</small>
                <span className="text-dark fontWeight700">00.00 USD</span>
              </div>
              <div className="paycItems d-flex align-items-center justify-content-between payTotal">
                <small className="text-dark fontWeight700">Total</small>
                <span className="text-dark fontWeight700">
                  {shortenValue(grandTotal)} USD
                </span>
              </div>
            </div>
          )}
          <ButtonComponent
            extraClass="w-100"
            title={onBtnLoader ? "Loading..." : "Checkout"}
            onClick={() => grandTotal !== 0 && onCheckout()}
            // children={onBtnLoader ? "Loading..." : "Checkout"}
            disabled={grandTotal == 0 && true}
            btnPrimary={true}
            btnLg={true}
          >
            {onBtnLoader ? (
              <LoadingOutlined className="spinLader" />
            ) : (
              "Checkout"
            )}
          </ButtonComponent>
        </div>
      </div>
    </>
  );
};

export default BasketComponent;
