import { Pplogo } from "svgIcon";
import classNames from "classnames";
import { useStyles } from "components/Layout/HeaderComponent/style";
import { faCartShopping, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useGlobalDisplay } from "utils/helper";

const HeaderComponent = () => {
  const classes = useStyles();
  const { setGlobalDisplayId, globalDisplayId } = useGlobalDisplay();
  const [screenSize, setScreenSize] = useState(window.innerWidth < 992);
  const pathname = window.location.pathname;
  const modifiedInput = pathname.replace("/pos/", "");
  const isSame = modifiedInput === pathname;

  useEffect(() => {
    if (!isSame) {
      const handleResize = () => {
        setScreenSize(window.innerWidth < 992);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [pathname]);

  return (
    <div className={classNames(classes.header)}>
      {!isSame && (
        <div className={classNames(classes.headerContainer, "container")}>
          <div className="hLogo">
            <Pplogo />
          </div>
          <div
            className={
              screenSize
                ? classNames(classes.removeCartIcn, "cursorPointer")
                : classNames(classes.addCartIcn, "cursorPointer")
            }
            onClick={() => setGlobalDisplayId(!globalDisplayId)}
          >
            <FontAwesomeIcon icon={faCartShopping} />
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderComponent;
