import React, { useState, useEffect } from "react";
import { SuccessLoader } from "svgIcon";
import { checkCoin, shortenAddress } from "utils/helper";

function PaymentSuccessComponent({
  generateJsonContent,
  manageDigest,
  merchantContent,
}: any) {
  const [url, setUrl] = useState("");
  const onRenderAddress = async (getDigest: any) => {
    const renderAddress = await checkCoin(
      generateJsonContent.blockchain,
      getDigest
    );
    if (renderAddress) {
      setUrl(renderAddress);
    }
  };
  const ePaymentVerification = () => {
    if (
      Object.keys(merchantContent).length > 0 &&
      merchantContent.order_type === "Paylinks" &&
      merchantContent.image_path === null &&
      merchantContent.call_back_url === null
    ) {
      const timer = setTimeout(() => {
        const input = window.location.pathname;
        const modifiedInput = input.replace("/", "");
        window.location.replace(
          `${merchantContent.redirect_url}/payment/${modifiedInput}`
        );
      }, 1500);
      return () => clearTimeout(timer);
    }
  };
  useEffect(() => {
    onRenderAddress(manageDigest);
    ePaymentVerification();
  }, []);
  return (
    <div className="text-center my-4">
      <>
        <SuccessLoader />
      </>
      <h3 className="m-0">
        Your order has been placed <br />
        successfully
      </h3>
      <span onClick={() => window.open(url, "_blank")}>
        View on explorer: {shortenAddress(manageDigest)}
      </span>
    </div>
  );
}
export default PaymentSuccessComponent;
