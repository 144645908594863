import { createUseStyles } from "react-jss";
import breakpoints from "assets/breakpoints";
export const useStyles = createUseStyles({
  cainList: {
    "& .clItems": {
      "&:not(:last-child)": {
        marginBottom: "20px",
      },
      "& > p": {
        fontSize: "14px",
        [breakpoints.SCREEN_MD_MAX]: {
          textAlign: "center",
        },
      },
      "& > h4": {
        fontSize: "22px",
        [breakpoints.SCREEN_MD_MAX]: {
          textAlign: "center",
        },
      },
      "& .clIApp": {
        [breakpoints.SCREEN_MD_MAX]: {
          justifyContent: "center",
          marginBottom: "20px",
        },
        "& svg": {
          [breakpoints.SCREEN_MD_MAX]: {
            width: "150px",
          },
          [breakpoints.SCREEN_MDS_MAX]: {
            width: "130px",
          },
        },
      },
    },
  },
  payQr: {
    textAlign: "center",
    "& h3": {
      lineHeight: "normal",
      fontSize: "17px",
    },
    "& .ant-image": {
      overflow: "hidden",
      borderRadius: "10px",
      border: "1px solid #F1F1F4",
      boxShadow: "0 0 30px rgb(0 0 0 / 10%)",
      "& .ant-image-mask": {
        width: "260px",
        height: "260px",
      },
    },
    "& canvas": {
      padding: "8px",
      borderRadius: "10px",
      border: "1px solid #F1F1F4",
      boxShadow: "0 0 30px rgb(0 0 0 / 10%)",
    },
  },
});
