import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  footer: {
    position: "relative",
    zIndex: "2",
    "& p": {
      fontSize: "14px",
    },
  },
});
