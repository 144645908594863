import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ExternalPayComponent from "components/ExternalPayment/ExternalPayComponent";

function ExternalPayContainer() {
  useEffect(() => {
    document.documentElement.classList.add("headerDeactivate");
  }, []);
  return (
    <>
      <Helmet>
        <title>Third Party | PocketPay Payment</title>
        <meta name="description" content="MTC" />
      </Helmet>
      <ExternalPayComponent />
    </>
  );
}

export default ExternalPayContainer;
