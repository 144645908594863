import { createUseStyles } from "react-jss";
import breakpoints from "assets/breakpoints";
export const useStyles = createUseStyles({
  catTContent: {
    "& .catTCBox": {
      borderRadius: "10px",
      background: "var(--white)",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#F1F1F4",
      boxShadow: "rgb(140 149 159 / 2%) 0px 8px 24px 0px",
      [breakpoints.SCREEN_SM_MIN]: {
        padding: "15px",
      },
      [breakpoints.SCREEN_SM_MAX]: {
        padding: "10px",
      },
      "& .catTCBImg": {
        overflow: "hidden",
        borderRadius: "10px",
        "&:hover": {
          "& .ant-image": {
            "& img": {
              transform: "scale(1.1) translateY(-6px)",
              MozTransform: "scale(1.1) translateY(-6px)",
              WebkitTransform: "scale(1.1) translateY(-6px)",
            },
          },
        },
        "& .ant-image": {
          width: "100%",
          borderRadius: "10px",
          [breakpoints.SCREEN_SM_MIN]: {
            height: "150px",
          },
          [breakpoints.SCREEN_SM_MAX]: {
            height: "120px",
          },
          [breakpoints.SCREEN_XS_MAX]: {
            height: "100px",
          },
          "& img": {
            objectFit: "cover",
            transform: "scale(1.2)",
            MozTransform: "scale(1.2)",
            WebkitTransform: "scale(1.2)",
            transition: "all 0.3s ease-in-out",
            MozTransition: "all 0.3s ease-in-out",
            WebkitTransition: "all 0.3s ease-in-out",
          },
          "& .ant-image-mask": {
            "&:hover": {
              "& .ant-image-mask-info": {
                opacity: "1",
                transform: "translateY(0)",
                MozTransform: "translateY(0)",
                WebkitTransform: "translateY(0)",
              },
            },
            "& .ant-image-mask-info": {
              transform: "translateY(40px)",
              MozTransform: "translateY(40px)",
              WebkitTransform: "translateY(40px)",
              opacity: "0",
              transition: "all 0.3s ease-in-out",
              MozTransition: "all 0.3s ease-in-out",
              WebkitTransition: "all 0.3s ease-in-out",
            },
          },
        },
      },
      "& .catTCBText": {
        paddingTop: "15px",
        [breakpoints.SCREEN_SM_MAX]: {
          flexDirection: "column",
        },
        "& .catTCBTTo": {
          flex: "1",
          overflow: "hidden",
          maxWidth: "calc(100% - 6px)",
          [breakpoints.SCREEN_SM_MAX]: {
            textAlign: "center",
          },
          "& p": {
            lineHeight: "normal",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          },
          "& > h3": {
            fontSize: "18px",
          },
        },
      },
    },
  },
});
