import { useState } from "react";
import CoinContentComponent from "../CoinContentComponent";
import CoinSelectionComponent from "../CoinSelectionComponent";

const CoinUsingComponent = ({
  orderPrice,
  chainPrice,
  selectedChain,
  setChainName,
  confirmCoin,
  setConfirmCoin,
}: any) => {
  const [showConversion, setShowConversion] = useState(false);
  const [runTimeSelectedName, setRunTimeSelectedChain] = useState("");
  const [chainContent, setChainContent] = useState({
    selectedChainPrice: "",
    SelectedChainAmount: "",
    selectedChainFormula: "",
  });

  return (
    <>
      <CoinSelectionComponent
        selectedChain={selectedChain}
        orderPrice={orderPrice}
        chainPrice={chainPrice}
        confirmCoin={confirmCoin}
        chainContent={chainContent}
        runTimeSelectedName={runTimeSelectedName}
        setConfirmCoin={setConfirmCoin}
        setChainName={setChainName}
        setChainContent={setChainContent}
        setRunTimeSelectedChain={setRunTimeSelectedChain}
        setShowConversion={setShowConversion}
      />
      <CoinContentComponent
        selectedChain={selectedChain}
        runTimeSelectedName={runTimeSelectedName}
        showConversion={showConversion}
        orderPrice={orderPrice}
        chainContent={chainContent}
      />
    </>
  );
};

export default CoinUsingComponent;
