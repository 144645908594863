import React from "react";
import { Image } from "antd";
import CautionLogo from "assets/images/blur.png";
import { useStyles } from "components/PaymentOptions/PayWithQR/PaymentCouldntFetchComponent/style";
import classNames from "classnames";

function PaymentCouldntFetchComponent(props: any) {
  const { onLoadGenerateQr, setShowTxnInput, qrKey } = props;
  const classes = useStyles();

  return (
    <div className={classNames(classes.lostQr, "ms-auto me-auto mb-2")}>
      <Image
        src={CautionLogo}
        className="cursorPointer"
        title="Click to Refresh for a New QR Code"
        alt="Click to Refresh for a New QR Code"
        preview={false}
        style={{ width: "260px", height: "260px" }}
        onClick={() => onLoadGenerateQr()}
      />
    </div>
  );
}
export default PaymentCouldntFetchComponent;
