import React, { useState } from "react";
import axios from "axios";
import {
  isClientAddress,
  networkUrl,
} from "containers/CheckoutContainer/constant";
import { ChainAptos, ChainSUI } from "utils/helper";

function PaymentVerifyBoxComponent(props: any) {
  const { onPaymentSuccess, qrKey, setQrStatus, setManageDigest } = props;
  const [txnError, SetTxnError] = useState("");
  const [txnDigest, SetTxnDigest] = useState("");
  const [showTxnLoader, setShowTxnLoader] = useState(false);

  const onFetchAptosInputDigest = async () => {
    // Assuming txnDigest is available in the current scope...
    if (txnDigest !== "") {
      try {
        // First, fetch the latest transaction hash
        const response = await axios.get(
          `https://fullnode.testnet.aptoslabs.com/v1/transactions/by_hash/${txnDigest}`
        );
        if (response && response.data && response.data.payload) {
          if (
            response.data.payload.arguments[2] == qrKey.amount * 100000000 &&
            response.data.payload.arguments[0] == qrKey.order_id.toString()
          ) {
            onPaymentSuccess(
              qrKey,
              txnDigest,
              response.data.payload.arguments[3],
              qrKey.order_id
            );
          } else {
            setShowTxnLoader(false);
            SetTxnError("The shared transaction id for this order is invalid");
          }
        }
      } catch (error) {
        setShowTxnLoader(false);
        SetTxnError("Please enter correct transaction id");
      } finally {
        setShowTxnLoader(false);
      }
    } else {
      setShowTxnLoader(false);
    }
  };

  const onFetchSUIInputDigest = () => {
    const data = {
      jsonrpc: "2.0",
      id: 1,
      method: "sui_getTransactionBlock",
      params: [
        txnDigest,
        {
          showInput: true,
          showEffects: true,
        },
      ],
    };
    axios
      .post(networkUrl, data)
      .then(({ data }) => {
        const { error } = data;
        if (error) {
          setShowTxnLoader(false);
          SetTxnError("Please enter correct transaction id");
        }
        const { result } = data;
        if (result) {
          const { transaction } = result;
          if (
            transaction &&
            transaction.data &&
            transaction.data.transaction.inputs[1].value ==
              qrKey.order_id.toString() &&
            transaction.data.transaction.inputs[4].value ==
              qrKey.amount * 1000000000 &&
            transaction.data.transaction.inputs[3].value == isClientAddress
          ) {
            const { effects } = result;
            if (
              effects &&
              effects.status &&
              effects.status.status === "success"
            ) {
              onPaymentSuccess(
                qrKey,
                data.digest,
                transaction.data.sender,
                qrKey.order_id
              );
            } else if (
              effects &&
              effects.status &&
              effects.status.status === "failure"
            ) {
              setQrStatus(3);
              setShowTxnLoader(false);
            }
          } else {
            setShowTxnLoader(false);
            SetTxnError("The shared transaction id for this order is invalid");
          }
        }
      })
      .catch((error) => {
        setShowTxnLoader(false);
        SetTxnError("Please enter correct transaction id");
      });
  };

  const onIdentiyInputDigest = () => {
    setShowTxnLoader(true);
    SetTxnError("");
    if (txnDigest !== "") {
      if (qrKey.blockchain === ChainSUI) {
        onFetchSUIInputDigest();
      } else if (qrKey.blockchain === ChainAptos) {
        onFetchAptosInputDigest();
      }
    } else {
      setShowTxnLoader(false);
      SetTxnError("Something went wrong");
    }
  };

  return (
    <div className="verifyWrapper">
      <div className="verifyWrapperBox">
        <span className="subInnerHeading">
          Please share your transaction id
        </span>
        <div className="verifyWrapperInput">
          <input
            type="text"
            placeholder="m2vkrtR35sgFiEXVdsepPAbm5Zmj93PRvWdBpkDdRPK9"
            className="inputMethod"
            onChange={(e) => SetTxnDigest(e.target.value)}
          />
          {showTxnLoader ? (
            <button className="disableBtn" disabled>
              Verify
            </button>
          ) : (
            <button
              onClick={() => onIdentiyInputDigest()}
              className="commonButtonSmall"
            >
              Verify
            </button>
          )}
        </div>
      </div>
      <p className="errorMessage">{txnError !== "" && `Error: ${txnError}`}</p>
    </div>
  );
}
export default PaymentVerifyBoxComponent;
