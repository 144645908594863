import { createUseStyles } from "react-jss";
import breakpoints from "assets/breakpoints";
export const useStyles = createUseStyles({
  conWallet: {
    "& .conBtn": {
      "& .iekbcc0": {
        display: "flex",
        justifyContent: "center",
        "& > button": {
          maxWidth: "260px",
          width: "100%",
          height: "auto",
          position: "relative",
          overflow: "hidden",
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "var(--fontWeight600)",
          color: "var(--white)",
          textAlign: "center",
          verticalAlign: "middle",
          userSelect: "none",
          backgroundColor: "var(--primary)",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "transparent",
          padding: "0.735rem 24px",
          cursor: "pointer",
          fontSize: "15px",
          lineHeight: "1.5",
          borderRadius: "8px",
          fontFamily: "var(--font-nunito)",
          WebkitTransition: "all 0.2s ease-in-out",
          transition: "all 0.2s ease-in-out",
          "&:before, &:after": {
            content: '""',
            height: "100%",
            position: "absolute",
            top: "0",
            left: "0",
            WebkitTransform: "translateX(-100px) skewX(-15deg)",
            transform: "translateX(-100px) skewX(-15deg)",
          },
          "&:before": {
            width: "60px",
            background: "rgba(255, 255, 255, 0.5)",
            filter: "blur(30px)",
            opacity: "0.5",
          },
          "&:after": {
            width: "30px",
            left: "30px",
            background: "rgba(255, 255, 255, 0.2)",
            filter: "blur(5px)",
          },
          "&:hover": {
            "&:before, &:after": {
              WebkitTransform: "translateX(400px) skewX(-15deg)",
              transform: "translateX(400px) skewX(-15deg)",
              WebkitTransition: "all 0.9s ease",
              transition: "all 0.9s ease",
            },
            "&::before": {
              opacity: "1",
            },
          },
          "&:hover": {
            color: "var(--white)",
            backgroundColor: "var(--primary)",
            WebkitTransform: "translateY(-2px)",
            transform: "translateY(-2px)",
            WebkitBoxShadow: "0 6px 12px rgb(64 78 183 / 30%)",
            boxShadow: "0 6px 12px rgb(64 78 183 / 30%)",
          },
        },
      },
      "& .walletConnect": {
        "& > .iekbcc0": {
          [breakpoints.SCREEN_MDS_MAX]: {
            flexDirection: "column",
            alignItems: "center",
          },
        },
        "& .chainKey": {
          [breakpoints.SCREEN_MDS_MAX]: {
            maxWidth: "100% !important",
          },
        },
        "& button": {
          "&[data-testid='rk-chain-button'], &[data-testid='rk-account-button']":
            {
              maxWidth: "unset",
              backgroundColor: "var(--secondary)",
              "& .iekbcc0": {
                borderColor: "rgb(255 255 255 / 10%)",
                borderRadius: "6px",
              },
            },
        },
      },
    },
  },
  cainList: {
    "& .clItems": {
      "&:not(:last-child)": {
        marginBottom: "20px",
      },
      "& > p": {
        fontSize: "14px",
      },
      "& > h4": {
        fontSize: "22px",
      },
    },
  },
});
