import React, { useEffect, useState } from "react";
import { BackIcon } from "svgIcon";
import { Tooltip } from "antd";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ChainBNB,
  ChainBase,
  ChainBlast,
  ChainCKBTC,
  ChainCKTestBTC,
  ChainLinea,
  ChainPolygon,
  ChainSUI,
  ChainTaiko,
} from "utils/helper";
import { useStyles } from "components/PaymentOptions/PayOptionsComponent/style";
import MerchantPayComponent from "components/Merchant/MerchantPayComponent";
import ButtonComponent from "components/Forms/ButtonComponent";
import PaymentQRComponent from "../PayWithQR/PaymentQRComponent";
import PaymentWalletComponent from "../PayWithWallet/PaymentWalletComponent";
import PaymentSuccessComponent from "../PayWithQR/PaymentSuccessComponent";

const PayOptionsComponent = ({
  cusEmail,
  fetchData,
  manageDigest,
  setManageDigest,
  setIsSelectChain,
  payWithWallet,
  setIsPaymentSuccess,
  qrContent,
  contentTitle,
  amount,
  setPayWithWallet,
  merchantContent,
  manageChainAmount,
  businessContent,
}: any) => {
  const classes = useStyles();
  const history = useHistory();
  const [showNextCompleted, setShowNextCompleted] = useState(false);

  const renderLink = (getName: any) => {
    if (getName === "POS") {
      history.push(`/pos/${businessContent.pos_domain_name}`);
    } else {
      window.open("https://dashboard.pocketpay.finance/", "_blank");
    }
  };

  const onManageBack = () => {
    if (showNextCompleted) {
      renderLink(merchantContent.order_type);
    } else {
      fetchData();
      setIsSelectChain(false);
      setPayWithWallet(false);
    }
  };

  useEffect(() => {
    document.body.classList.add("processPay");
  }, []);

  useEffect(() => {
    const supportedChains = [
      ChainCKTestBTC,
      ChainCKBTC,
      ChainBlast,
      ChainBNB,
      ChainPolygon,
      ChainBase,
      ChainLinea,
      ChainTaiko,
    ];
    if (
      Object.keys(qrContent).length > 0 &&
      supportedChains.includes(qrContent.blockchain)
    ) {
      setPayWithWallet(true);
    }
  }, [qrContent]);

  return (
    <>
      <div className="container bContainer">
        <div className={classNames(classes.payOrder, "m-auto")}>
          <div className="poHead d-flex align-items-center mb-2">
            <div
              title="Back"
              className="cursorPointer d-flex align-items-center"
              onClick={() => onManageBack()}
            >
              <BackIcon />
            </div>
            <h4 className="my-0 ms-3 fontWeight700 text-dark">
              Pay for your order
            </h4>
          </div>
          <MerchantPayComponent
            payWithWallet={payWithWallet}
            merchantContent={merchantContent}
            contentTitle={contentTitle}
            qrContent={qrContent}
          />
          <div className={classNames(classes.merchantInfo, "mb-3 bg-white")}>
            {showNextCompleted ? (
              <PaymentSuccessComponent
                generateJsonContent={qrContent}
                manageDigest={manageDigest}
                merchantContent={merchantContent}
              />
            ) : (
              <>
                {payWithWallet ? (
                  <PaymentWalletComponent
                    amount={amount}
                    cusEmail={cusEmail}
                    setIsPaymentSuccess={setIsPaymentSuccess}
                    setManageDigest={setManageDigest}
                    generateJsonContent={qrContent}
                    qrContentTitle={contentTitle}
                    merchantContent={merchantContent}
                    setShowNextCompleted={setShowNextCompleted}
                  />
                ) : (
                  <PaymentQRComponent
                    amount={amount}
                    cusEmail={cusEmail}
                    setIsPaymentSuccess={setIsPaymentSuccess}
                    setManageDigest={setManageDigest}
                    generateJsonContent={qrContent}
                    qrContentTitle={contentTitle}
                    merchantContent={merchantContent}
                    businessContent={businessContent}
                    setShowNextCompleted={setShowNextCompleted}
                  />
                )}
              </>
            )}
          </div>
          <div
            className={classNames(
              classes.merchantAddress,
              "mb-3 bg-white d-flex flex-wrap align-items-center justify-content-between"
            )}
          >
            <div className="meaLe">
              <p className="m-0 text-dark fontWeight600 opacity70">
                Merchant Address
              </p>
              <h4 className="m-0 text-dark fontWeight800">
                {qrContent && qrContent?.merchant_address}
              </h4>
            </div>
            <div className="meaRi">
              <Tooltip
                placement="top"
                className="cursorPointer"
                title="Copy code"
              >
                <span className="d-flex">
                  <FontAwesomeIcon
                    className="cursorPointer"
                    style={{ width: "18", height: "18" }}
                    icon={faCopy}
                  />
                </span>
              </Tooltip>
            </div>
          </div>
          {qrContent && qrContent?.blockchain === ChainSUI && (
            <div className="mt-2 text-center">
              <p className="mt-0 mb-3 text-dark fontWeight600">OR</p>
              {payWithWallet ? (
                <ButtonComponent
                  className="w-100"
                  style={{ maxWidth: "260px" }}
                  title={"Pay With QR"}
                  onClick={() => setPayWithWallet(false)}
                  children={"Pay With QR"}
                  btnSecondary={true}
                />
              ) : (
                <ButtonComponent
                  className="w-100"
                  style={{ maxWidth: "260px" }}
                  title={"Pay With Wallet"}
                  onClick={() => setPayWithWallet(true)}
                  children={"Pay With Wallet"}
                  btnSecondary={true}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PayOptionsComponent;
