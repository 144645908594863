import { createUseStyles } from "react-jss";
import breakpoints from "assets/breakpoints";
export const useStyles = createUseStyles({
  catTNav: {
    "& .catTItems": {
      borderWidth: "2px",
      borderStyle: "solid",
      borderRadius: "10px",
      borderColor: "var(--whitesmoke)",
      transition: "all 0.3s ease-in-out",
      MozTransition: "all 0.3s ease-in-out",
      WebkitTransition: "all 0.3s ease-in-out",
      boxShadow: "rgb(140 149 159 / 2%) 0px 8px 24px 0px",
      [breakpoints.SCREEN_MD_MIN]: {
        maxWidth: "125px",
        flex: "0 0 125px",
        padding: "26px 0",
      },
      [breakpoints.SCREEN_MD_MAX]: {
        padding: "15px",
        display: "flex",
        alignContent: "center",
      },
      "&:hover": {
        transform: "translateY(-4px)",
        MozTransform: "translateY(-4px)",
        WebkitTransform: "translateY(-4px)",
        boxShadow: "0px 32px 24px -16px rgba(1, 40, 120, 0.06)",
      },
      "&.active": {
        borderColor: "rgba(8, 209, 149, 0.6)",
        boxShadow: "0px 32px 24px -16px rgba(1, 40, 120, 0.08)",
        "&:hover": {
          transform: "unset",
        },
      },
      "& .catTIImg": {
        [breakpoints.SCREEN_MD_MAX]: {
          display: "none !important",
          visibility: "hidden !important",
          opacity: "0 !important",
        },
        "& .ant-image": {
          width: "40px",
          height: "40px",
          overflow: "hidden",
          borderRadius: "6px",
          "& .ant-image-img": {
            "&.ciiImg": {
              width: "100%",
              height: "100%",
              objectFit: "cover",
            },
          },
        },
      },
      "& .catTITitle": {
        [breakpoints.SCREEN_MD_MAX]: {
          display: "flex",
        },
        [breakpoints.SCREEN_MD_MIN]: {
          marginTop: "10px",
        },
        "& > p": {
          fontSize: "14px",
          [breakpoints.SCREEN_MD_MAX]: {
            alignSelf: "center",
            whiteSpace: "nowrap",
          },
        },
        "& > small": {
          [breakpoints.SCREEN_MD_MAX]: {
            display: "none !important",
            visibility: "hidden !important",
            opacity: "0 !important",
          },
        },
      },
    },
  },
});
