import React from "react";
import propTypes from "prop-types";
import classnames from "classnames";
import { useStyles } from "./style";
const ButtonComponent = ({
  children,
  onClick,
  className,
  disabled,
  type,
  title,
  bgColor,
  color,
  btnLink,
  btnPrimary,
  btnPrimaryOutline,
  btnSecondary,
  btnSecondaryOutline,
  btnSuccess,
  btnDanger,
  btnWarning,
  btnLight,
  btnInfo,
  btnLg,
  btnSm,
  extraClass,
  width,
  style,
  preFixIcon,
  postFixIcon,
  peNone,
  buttonRef,
  theme,
  loading,
  ...rest
}: any) => {
  const classes = useStyles({ width } as any);
  return (
    <button
      disabled={loading ? loading : disabled}
      title={title}
      type={type}
      ref={buttonRef}
      className={classnames(
        classes.btn,
        className,
        extraClass,
        btnLink && classes.btnLink,
        btnPrimary && classes.btnPrimary,
        btnPrimaryOutline && classes.btnPrimaryOutline,
        btnSecondary && classes.btnSecondary,
        btnSecondaryOutline && classes.btnSecondaryOutline,
        btnSuccess && classes.btnSuccess,
        btnDanger && classes.btnDanger,
        btnWarning && classes.btnWarning,
        btnInfo && classes.btnInfo,
        btnLight && classes.btnLight,
        btnLg && classes.btnLg,
        btnSm && classes.btnSm
      )}
      onClick={onClick}
      style={style}
      {...rest}
    >
      {preFixIcon}
      {children}
      {postFixIcon}
    </button>
  );
};
ButtonComponent.propTypes = {
  theme: propTypes.object,
  children: propTypes.any.isRequired,
  onClick: propTypes.func,
  className: propTypes.string,
  title: propTypes.string,
  disabled: propTypes.bool,
  btnLink: propTypes.bool,
  btnPrimary: propTypes.bool,
  btnPrimaryOutline: propTypes.bool,
  btnSuccess: propTypes.bool,
  primaryButton: propTypes.bool,
  secondaryButton: propTypes.bool,
  btnSecondary: propTypes.bool,
  btnSecondaryOutline: propTypes.bool,
  blueBorderButton: propTypes.bool,
  deleteButton: propTypes.bool,
  btnSm: propTypes.bool,
  btnLg: propTypes.bool,
  width: propTypes.string,
  style: propTypes.object,
  extraClass: propTypes.string,
};
ButtonComponent.defaultProps = {
  onClick: () => false,
  className: "",
  disabled: false,
  width: "200px",
};
export default ButtonComponent;
