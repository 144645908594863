import classNames from "classnames";
import { useStyles } from "components/Layout/FooterComponent/style";

const FooterComponent = () => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.footer)}>
      <div className="container">
        <p className="m-0 pt-3 pb-2 text-center">
          Powered by <strong>PocketPay Finance</strong>
          {/* <span className="d-inline-flex opacity30 mx-1">|</span> Decentralised
          Payment Protocol */}
        </p>
      </div>
    </div>
  );
};

export default FooterComponent;
