import React from "react";
import PropTypes from "prop-types";
import LabelComponent from "components/Forms/LabelComponent";
import { useformCommons } from "components/Forms/formCommon";
import { useStyles } from "./style";
import classNames from "classnames";

const TextBoxComponent = ({
  error = "",
  label = "",
  subTitle = "",
  mandatory,
  title,
  className,
  extraClass,
  labelExtraIcon,
  loading,
  style,
  theme,
  ...rest
}: any) => {
  const classes = useStyles();
  const commonClass = useformCommons(theme);
  return (
    <div className={classNames(commonClass.formGrp, extraClass)} style={style}>
      {label && (
        <LabelComponent>
          {label} {mandatory && <span className="mandatory">*</span>}
          {labelExtraIcon}
        </LabelComponent>
      )}
      {subTitle && <small className={"d-block mb-2"}>{subTitle}</small>}
      <input
        maxLength={100}
        aria-label={title ? title : label}
        aria-labelledby={title ? title : label}
        title={title ? title : label}
        className={classNames(
          className,
          commonClass.formControl,
          {
            [commonClass.mandatory]: mandatory,
          },
          error && classes.error
        )}
        autoComplete="off"
        {...rest}
      />
    </div>
  );
};

TextBoxComponent.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func, // Fix the typo in the prop name
  className: PropTypes.string,
  extraClass: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool, // Fix the typo in the prop name
  placeholder: PropTypes.string,
  mandatory: PropTypes.bool,
  error: PropTypes.string, // Add the missing 'error' property
};

export default TextBoxComponent;
