import { createUseStyles } from "react-jss";
import breakpoints from "assets/breakpoints";
export const useStyles = createUseStyles({
  basketCart: {
    [breakpoints.SCREEN_LG_MIN]: {
      overflow: "auto",
      minHeight: "400px",
    },
    [breakpoints.SCREEN_LG_MAX]: {
      maxHeight: "400px",
    },
    "& .basCItems": {
      "&:not(:last-child)": {
        paddingBottom: "10px",
      },
      "& .bciLe": {
        "& .bciLImg": {
          height: "42px",
          "& .ant-image": {
            width: "50px",
            height: "42px",
            overflow: "hidden",
            borderRadius: "6px",
            "& .ant-image-img": {
              width: "100%",
              height: "100%",
              objectFit: "cover",
            },
          },
        },
        "& .bciLText": {
          paddingLeft: "10px",
          "& > small": {
            fontSize: "13px",
            opacity: "0.8",
          },
          "& > span": {
            fontSize: "15px",
          },
        },
      },
      "& .bciRi": {
        "& > button": {
          width: "26px",
          height: "26px",
          borderRadius: "6px",
          border: "0",
          backgroundColor: "#E6EAF2",
          "& svg": {
            "& > path": {
              fill: "var(--dark)",
            },
          },
          "&:hover": {
            backgroundColor: "var(--primary)",
            "& svg": {
              "& > path": {
                fill: "var(--white)",
              },
            },
          },
          "&[disabled]": {
            "&:hover": {
              backgroundColor: "#E6EAF2",
              "& svg": {
                "& > path": {
                  fill: "var(--dark)",
                },
              },
            },
          },
          "&.bciMinus": {},
          "&.bciPlus": {},
        },
        "&.bciCount": {},
      },
    },
  },
  payCard: {
    padding: "14px",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    border: "1px solid #f1f1f4",
    "& .paycItems": {
      "&:not(:last-child)": {
        marginBottom: "2px",
      },
      "& > small": {
        fontSize: "14px",
      },
      "& > span": {
        fontSize: "16px",
      },
      "&.payHead": {
        borderBottom: "1px solid #f1f1f4",
        paddingBottom: "8px",
        marginBottom: "8px",
      },
      "&.payTotal": {
        "& > small, & > span": {
          fontSize: "18px",
        },
      },
    },
  },
  emptyBasket: {
    "& img": {},
    "& h4": {
      fontSize: "18px",
    },
    "& p": {
      fontSize: "14px",
    },
  },
});
