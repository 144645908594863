import { createUseStyles } from "react-jss";
import breakpoints from "assets/breakpoints";
export const useStyles = createUseStyles({
  mainTtle: {
    fontSize: "20px",
    lineHeight: "normal",
  },
  mainLe: {},
  mainRi: {
    position: "sticky",
    top: "10px",
    background: "var(--white)",
    borderRadius: "10px",
    border: "1px solid #f1f1f4",
    boxShadow: "rgb(140 149 159 / 2%) 0px 8px 24px 0px",
    [breakpoints.SCREEN_LG_MIN]: {
      minHeight: "100%",
      height: "calc(100% - 45px)",
      maxHeight: "calc(750px - 0px)",
    },
    "& .mriTitle": {
      fontSize: "20px",
      padding: "16px 18px",
      borderBottom: "1px solid #F1F1F4",
    },
    "& .mriBody": {
      padding: "18px",
      [breakpoints.SCREEN_LG_MIN]: {
        height: "calc(100% - 63px)",
      },
      "& .mribTo": {
        flex: "1",
      },
      "& .mribBo": {},
    },
  },
  blockChainList: {
    [breakpoints.SCREEN_LG_MIN]: {
      marginLeft: "-10px",
      marginRight: "-10px",
    },
    [breakpoints.SCREEN_LG_MAX]: {
      marginLeft: "-8px",
      marginRight: "-8px",
    },
    [breakpoints.SCREEN_SM_MAX]: {
      marginLeft: "-6px",
      marginRight: "-6px",
    },
    "& .bclItems": {
      [breakpoints.SCREEN_LG_MIN]: {
        width: "20%",
        paddingLeft: "10px",
        paddingRight: "10px",
        marginBottom: "20px",
      },
      [breakpoints.SCREEN_LG_MAX]: {
        width: "33.33%",
        paddingLeft: "8px",
        paddingRight: "8px",
        marginBottom: "16px",
      },
      [breakpoints.SCREEN_SM_MAX]: {
        width: "50%",
        paddingLeft: "6px",
        paddingRight: "6px",
        marginBottom: "12px",
      },
      "&:hover": {
        "& .bclIInner": {
          transform: "translateY(-3px)",
          boxShadow: "0px 32px 24px -16px rgba(1, 40, 120, 0.06)",
        },
      },
      "&.active": {
        "& .bclIInner": {
          borderColor: "rgba(8, 209, 149, 0.5)",
          boxShadow: "0px 32px 24px -16px rgba(1, 40, 120, 0.08)",
        },
        "&:hover": {
          "& .bclIInner": {
            transform: "unset",
            boxShadow: "0px 32px 24px -16px rgba(1, 40, 120, 0.08)",
          },
        },
      },
      "& .bclIInner": {
        width: "100%",
        borderRadius: "10px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#f1f1f4",
        backgroundColor: "var(--white)",
        boxShadow: "rgb(140 149 159 / 2%) 0px 8px 24px 0px",
        cursor: "pointer",
        transition: "all 0.3s ease-in-out",
        MozTransition: "all 0.3s ease-in-out",
        WebkitTransition: "all 0.3s ease-in-out",
        [breakpoints.SCREEN_LG_MIN]: {
          padding: "20px 8px",
        },
        [breakpoints.SCREEN_LG_MAX]: {
          padding: "15px 6px",
        },
        "& > span": {
          "&:last-child": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: "1",
            position: "relative",
          },
        },
        "& .bclSelect": {
          position: "absolute",
          zIndex: "99",
          transition: "all 0.3s ease-in-out",
          MozTransition: "all 0.3s ease-in-out",
          WebkitTransition: "all 0.3s ease-in-out",
          [breakpoints.SCREEN_LG_MIN]: {
            top: "-16px",
            right: "-5px",
          },
          [breakpoints.SCREEN_LG_MAX]: {
            top: "-13px",
            right: "-4px",
          },
          [breakpoints.SCREEN_SM_MAX]: {
            top: "-10px",
            right: "-2px",
          },
        },
        "& .bclIcn": {
          [breakpoints.SCREEN_MD_MIN]: {
            width: "56px",
            height: "56px",
          },
          [breakpoints.SCREEN_MD_MAX]: {
            width: "46px",
            height: "46px",
          },
          marginBottom: "14px",
          "& svg": {
            [breakpoints.SCREEN_MD_MIN]: {
              width: "56px",
              height: "56px",
            },
            [breakpoints.SCREEN_MD_MAX]: {
              width: "46px",
              height: "46px",
            },
          },
        },
        "& .bclText": {
          fontSize: "16px",
        },
      },
    },
    "& span.ant-radio.ant-wave-target": {
      opacity: 0,
      display: "none",
    },
  },
});
