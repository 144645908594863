import axios from "axios";
import { baseURL } from "utils/env";
import { dateAndTimeFormat, decodeContent, extractID } from "utils/helper";

export const isClientName = "pocketpay.sui";
export const isClientChain = "sui";
export const isMerchantName = "PocketPay Kiosk";
export const isCoinName = "SUI";
export const isClientNetwork = "Testnet";
export const endOfTime = 45;
export const networkUrl = "https://wallet-rpc.testnet.sui.io/";
export const isClientAddress =
  "0x3121399d6ca64e08428d7e0010ac23c269ec379ac6f7fa411cb1c994c11b6f63";
export const packageId =
  "0x2b4232ac9939d5569a8ef75f951c8448749b02a25143000d89c336238ec69558";
export const isCoinType =
  "0x0000000000000000000000000000000000000000000000000000000000000002::sui::SUI";

export const updatePosStatusWithApi = async (
  content,
  digest,
  sender,
  merchantContent,
  onSuccess,
  cusEmail
) => {
  const getDateAndTime = await dateAndTimeFormat();
  const merchantObject = {
    amount: merchantContent.amount,
    client_address: sender,
    customer_email: cusEmail,
    coin: content.blockchain,
    coin_amount: content.amount,
    payment_id: content.order_id,
    date_time: `${getDateAndTime}`,
    digest: digest,
    status: "completed",
    order_type: merchantContent.order_type,
    merchant_email: merchantContent.merchant_email,
    merchant_name: merchantContent.merchant_name,
    merchant_address: content.merchant_address,
    logo_path: merchantContent.logo_path,
    network: " ",
    business_name: "demo business",
  };
  try {
    axios
      .put(
        `${baseURL}merchant_orders/${merchantContent.order_id}`,
        merchantObject
      )
      .then((response) => {
        onSuccess(digest);
      });
  } catch (error) {}
};

export const updatePaylinkStatusWithApi = async (
  content,
  digest,
  sender,
  merchantContent,
  onSuccess,
  cusEmail
) => {
  const getDateAndTime = await dateAndTimeFormat();
  const merchantObject = {
    amount: merchantContent.amount,
    sender_address: sender,
    customer_email: cusEmail,
    coin: content.blockchain,
    coin_amount: content.amount,
    payment_id: content.order_id,
    date_time: `${getDateAndTime}`,
    txn_digest: digest,
    status: "completed",
    order_type: merchantContent.order_type,
    link_expire_time: merchantContent.link_expire_time,
    merchant_email: merchantContent.merchant_email,
    merchant_name: merchantContent.merchant_name,
    merchant_address: content.merchant_address,
    logo_path: merchantContent.logo_path,
    network: " ",
    business_name: "demo business",
  };
  try {
    axios
      .put(
        `${baseURL}create_paylinks/${merchantContent.order_id}`,
        merchantObject
      )
      .then((response) => {
        onSuccess(digest);
      });
  } catch (error) {}
};

export const manageCoinsSequences = (originalArray, setChainList) => {
  const desiredSequence = ["Solana", "SUI", "Blast"];
  // Sort the original array based on the desired sequence
  const sortedArray = originalArray.sort(
    (a, b) =>
      desiredSequence.indexOf(a.blockchain_name) -
      desiredSequence.indexOf(b.blockchain_name)
  );
  // Modify the sequence of sub_coin arrays within each object
  const modifiedArray = sortedArray.map((item) => {
    if (item.sub_coin && item.sub_coin.length > 0) {
      // Sort sub_coin arrays for other objects based on the desired sequence
      item.sub_coin.sort(
        (a, b) =>
          desiredSequence.indexOf(a.blockchain_name) -
          desiredSequence.indexOf(b.blockchain_name)
      );
    }
    return item;
  });
  setChainList(modifiedArray);
};
