/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Col, Image, Row, Tooltip } from "antd";
import { useBasket } from "containers/ProductContainer/constant";
import { baseImageURL } from "utils/env";
import { useStyles } from "components/Product/ProductListComponent/style";
import classNames from "classnames";
import MonogramIcon from "assets/images/mongram.svg";
import { toastMessage } from "utils/helper";
import ButtonComponent from "components/Forms/ButtonComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";

const ProductListComponent = ({
  viewName,
  productList,
  catClick,
  setCatClick,
  newOne,
  setNewOne,
}: any) => {
  const classes = useStyles();
  const { addToBasket, removeFromBasket, itemList } = useBasket();
  const [isHaveSubCategory, setIsHaveCategory] = useState(false);
  const [filterProductList, setFilterProductList] = useState<any[]>([]);
  const [labelList, setLabelList] = useState<string[]>([]);
  const [addedToCart, setAddedToCart] = useState<{
    [productId: string]: boolean;
  }>({});

  const addToCart = (productId: string, product: any) => {
    addToBasket(product);
    setAddedToCart((prevAddedToCart) => ({
      ...prevAddedToCart,
      [productId]: true,
    }));
    toastMessage("Item added to basket");
  };

  const removeFromCart = (productId: string) => {
    removeFromBasket(productId);
    setAddedToCart((prevAddedToCart) => ({
      ...prevAddedToCart,
      [productId]: false,
    }));
    toastMessage("Item removed from basket");
  };

  const handleSubCategoryClick = (clickedCategory: any) => {
    setCatClick(clickedCategory);
    setNewOne(2);
  };

  useEffect(() => {
    if (catClick && catClick.category_id) {
      setIsHaveCategory(false);
      if (catClick.category_id === 99001) {
        setFilterProductList(productList);
      } else {
        if (
          catClick &&
          catClick.sub_categories &&
          catClick.sub_categories.length > 0
        ) {
          newOne === 1
            ? setLabelList([catClick.category_name])
            : setLabelList([...labelList, catClick.category_name]);
          setIsHaveCategory(true);
          setFilterProductList([]);
        } else {
          const filteredProducts = productList.filter((product: any) => {
            return product.categories.some((category: any) => {
              return category.name === catClick.category_name;
            });
          });
          setFilterProductList(filteredProducts);
          setIsHaveCategory(false);
        }
      }
    }
  }, [catClick]);

  return (
    <>
      <div className={classNames(classes.catTContent)}>
        <Row gutter={{ xs: 10, sm: 10, md: 14, lg: 20 }}>
          {filterProductList &&
            filterProductList.map((product: any) => (
              <Col
                key={product.product_id}
                className="gutter-row mb-2 mb-md-3"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
              >
                <div className="catTCBox">
                  <div className="catTCBImg">
                    {product.img !== null ? (
                      <Image
                        className="w-100 h-100"
                        src={`${baseImageURL}${product.img}`}
                        alt="Product image"
                      />
                    ) : (
                      <Image src={MonogramIcon} alt="Monogram" />
                    )}
                  </div>
                  <div className="catTCBText d-flex flex-wrap align-items-center justify-content-between">
                    <div className="catTCBTTo">
                      <p
                        className="m-0 p-0 fontWeight600"
                        title={product.product_name}
                      >
                        {product.product_name}
                      </p>
                      <h3 className="m-0 p-0 fontWeight800">
                        {product.price} USD
                      </h3>
                    </div>
                    <div className="catTCBTBo">
                      <ButtonComponent
                        onClick={() =>
                          addedToCart[product.product_id]
                            ? removeFromCart(product.product_id)
                            : addToCart(product.product_id, product)
                        }
                        children={
                          addedToCart[product.product_id] ? (
                            <>
                              <FontAwesomeIcon
                                className="me-1"
                                icon={faCheck}
                              />
                              Added
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon className="me-1" icon={faPlus} />
                              Add
                            </>
                          )
                        }
                        btnSm
                        btnPrimary={
                          addedToCart[product.product_id] ? false : true
                        }
                        btnSuccess={
                          addedToCart[product.product_id] ? true : false
                        }
                      />
                    </div>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    </>
  );
};

export default ProductListComponent;
