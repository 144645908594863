/* eslint-disable jsx-a11y/img-redundant-alt */
import { Col, Image, Row, Tooltip } from "antd";
import { baseImageURL } from "utils/env";
import MonogramIcon from "assets/images/mongram.svg";
import { useStyles } from "components/Merchant/MerchantPayComponent/style";
import classNames from "classnames";
import { LoadingOutlined } from "@ant-design/icons";
import { identifyCoinNetwork, selectedChainIcon } from "utils/helper";

const MerchantPayComponent = ({
  payWithWallet,
  merchantContent,
  qrContent,
}: any) => {
  const classes = useStyles();
  return (
    <>
      {merchantContent ? (
        <>
          <div
            className={classNames(
              classes.merCard,
              "merCardCommon d-flex flex-md-row flex-column align-items-md-center justify-content-md-between justify-content-start mb-3"
            )}
          >
            <div className="merCLe d-flex align-items-center">
              <div className="merCImg">
                {merchantContent.logo_path !== null ? (
                  <Image
                    className="w-100 h-100"
                    src={`${baseImageURL}${merchantContent.logo_path}`}
                    alt="Profile Image"
                    preview={false}
                  />
                ) : (
                  <Image
                    src={MonogramIcon}
                    alt="Profile Image"
                    preview={false}
                  />
                )}
              </div>
              <div className="merCText d-flex flex-column ms-3">
                <h3 className="m-0 text-dark fontWeight800">
                  {merchantContent.business_name}
                </h3>
                <p className="m-0 text-dark fontWeight600 opacity50">
                  {merchantContent.business_description}
                </p>
              </div>
            </div>
            {payWithWallet && (
              <>
                <div className="merCRi d-flex flex-column align-items-md-end align-items-start">
                  <div className="merCROn mb-1 text-end">
                    <h4 className="m-0 fontWeight800">
                      Network - {identifyCoinNetwork(qrContent.blockchain)}
                    </h4>
                  </div>
                  <div className="merCRTw d-flex align-items-center">
                    {selectedChainIcon(qrContent.blockchain)}
                    <p className="ps-1 m-0 text-dark fontWeight600">
                      {qrContent.blockchain}
                    </p>
                  </div>
                </div>
                {/* <div className="merCRi d-flex flex-column">
                  <p className="m-0 text-dark fontWeight600">
                    Chain : {selectedChainIcon(qrContent.blockchain)}{" "}
                  </p>
                  <h3 className="m-0 text-dark fontWeight600 opacity50">
                    Network : {identifyCoinNetwork(qrContent.blockchain)}
                  </h3>
                </div> */}
              </>
            )}
          </div>
          {Object.keys(qrContent).length === 0 &&
            merchantContent.products &&
            merchantContent.products.length > 0 && (
              <>
                <h3 className={classNames(classes.mainTtle, "mt-0 mb-1")}>
                  Selected Products
                </h3>
                <Row
                  gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }}
                  className="mb-3"
                  style={{ rowGap: "10px" }}
                >
                  {merchantContent.products.map((item: any, index: any) => {
                    return (
                      <Col
                        span={4}
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 8 }}
                        lg={{ span: 6 }}
                        xl={{ span: 4 }}
                      >
                        <Tooltip placement="top" title={item.name}>
                          <div
                            className={classNames(
                              classes.spBox,
                              "d-flex flex-column"
                            )}
                            key={index}
                          >
                            <div className="spbImg">
                              {item.img !== null ? (
                                <Image
                                  width={122}
                                  height={70}
                                  src={`${baseImageURL}${item.img}`}
                                  alt="Product Image"
                                  preview={false}
                                />
                              ) : (
                                <Image
                                  width={70}
                                  src={MonogramIcon}
                                  alt="Monogram"
                                  preview={false}
                                />
                              )}
                            </div>
                            <div className="spbText d-flex align-items-center fontWeight700">
                              <span>{item.name}</span>
                            </div>
                          </div>
                        </Tooltip>
                      </Col>
                    );
                  })}
                </Row>
              </>
            )}
        </>
      ) : (
        <LoadingOutlined className="spinLader" />
      )}
    </>
  );
};

export default MerchantPayComponent;
