import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  formLabel: {
    color: (props) => (props ? props.__form_label : "var(--darkMore)"),
    fontSize: "15px",
    lineHeight: "1.2",
    marginBottom: "3px",
    display: "inline-block",
    fontWeight: "var(--fontWeight600)",
    "& > .mandatory": {
      color: (props) => (props ? props.__form_mandatory : "var(--dangerDark)"),
    },
  },
});
