import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  header: {
    borderBottom: "1px solid #F1F1F4",
    backgroundColor: "var(--white)",
    "& .hLogo": {
      display: "flex",
      alignItems: "center",
      paddingTop: "18px",
      paddingBottom: "18px",
    },
  },
  noneCartIcn: {
    display: "none",
  },
  removeCartIcn: {
    display: "contents",
    "& svg": {
      width: "22px",
      height: "22px",
      "& > path": {
        fill: "var(--primary)",
      },
    },
  },
  addCartIcn: {
    display: "none",
    "& svg": {
      width: "22px",
      height: "22px",
      "& > path": {
        fill: "var(--primary)",
      },
    },
  },
});
