import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  btn: {
    position: "relative",
    overflow: "hidden",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "var(--fontWeight600)",
    color: "var(--dark)",
    textAlign: "center",
    verticalAlign: "middle",
    userSelect: "none",
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "transparent",
    padding: "0.735rem 24px",
    cursor: "pointer",
    fontSize: "15px",
    lineHeight: "1.5",
    borderRadius: "8px",
    fontFamily: "var(--font-nunito)",
    WebkitTransition: "all 0.3s ease-in-out",
    transition: "all 0.3s ease-in-out",
    " *": {
      WebkitTransition: "all 0.3s ease-in-out",
      transition: "all 0.3s ease-in-out",
    },
    "&:before, &:after": {
      content: '""',
      height: "100%",
      position: "absolute",
      top: "0",
      left: "0",
      WebkitTransform: "translateX(-100px) skewX(-15deg)",
      transform: "translateX(-100px) skewX(-15deg)",
    },
    "&:before": {
      width: "60px",
      background: "rgba(255, 255, 255, 0.5)",
      filter: "blur(30px)",
      opacity: "0.5",
    },
    "&:after": {
      width: "30px",
      left: "30px",
      background: "rgba(255, 255, 255, 0.2)",
      filter: "blur(5px)",
    },
    "&:hover": {
      "&:before, &:after": {
        WebkitTransform: "translateX(400px) skewX(-15deg)",
        transform: "translateX(400px) skewX(-15deg)",
        WebkitTransition: "all 0.9s ease",
        transition: "all 0.9s ease",
      },
      "&::before": {
        opacity: "1",
      },
    },
  },
  btnPrimary: {
    color: "var(--white)",
    backgroundColor: "var(--primary)",
    "&:hover": {
      color: "var(--white)",
      backgroundColor: "var(--primary)",
      WebkitTransform: "translateY(-2px)",
      transform: "translateY(-2px)",
      WebkitBoxShadow: "0 6px 12px rgb(64 78 183 / 30%)",
      boxShadow: "0 6px 12px rgb(64 78 183 / 30%)",
    },
    "&:focus, &.focus, &[href]:hover, &[href]:focus, &[href]:active, &[type]:hover, &[type]:focus, &[type]:active ":
      {
        // WebkitBoxShadow: "0 6px 12px rgb(64 78 183 / 30%)",
        // boxShadow: "0 6px 12px rgb(64 78 183 / 30%)",
      },
  },
  btnPrimaryOutline: {
    color: "var(--primary)",
    backgroundColor: "var(--white)",
    borderColor: "var(--primary)",
    borderWidth: "1px",
    "&:hover": {
      color: "var(--white)",
      backgroundColor: "var(--primary)",
      WebkitTransform: "translateY(-2px)",
      transform: "translateY(-2px)",
      WebkitBoxShadow: "0 6px 12px rgb(64 78 183 / 30%)",
      boxShadow: "0 6px 12px rgb(64 78 183 / 30%)",
    },
    "&:focus, &.focus, &[href]:hover, &[href]:focus, &[href]:active, &[type]:hover, &[type]:focus, &[type]:active ":
      {
        // WebkitBoxShadow: "0 6px 12px rgb(64 78 183 / 30%)",
        // boxShadow: "0 6px 12px rgb(64 78 183 / 30%)",
      },
    "&[disabled]": {
      color: "var(--primary)",
      backgroundColor: "var(--white)",
    },
  },
  btnSuccess: {
    color: "var(--white)",
    backgroundColor: "var(--success)",
    "&:hover": {
      color: "var(--white)",
      backgroundColor: "var(--success)",
      WebkitTransform: "translateY(-2px)",
      transform: "translateY(-2px)",
      WebkitBoxShadow: "0 6px 12px rgb(8 209 149 / 30%)",
      boxShadow: "0 6px 12px rgb(8 209 149 / 30%)",
    },
    "&:focus, &.focus, &[href]:hover, &[href]:focus, &[href]:active, &[type]:hover, &[type]:focus, &[type]:active ":
      {
        // WebkitBoxShadow: "0 6px 12px rgb(8 209 149 / 30%)",
        // boxShadow: "0 6px 12px rgb(8 209 149 / 30%)",
      },
  },
  btnSecondary: {
    color: "var(--white)",
    backgroundColor: "var(--secondary)",
    "&:hover": {
      color: "var(--white)",
      backgroundColor: "var(--secondary)",
      WebkitTransform: "translateY(-2px)",
      transform: "translateY(-2px)",
      WebkitBoxShadow: "0 6px 12px rgb(75 63 79 / 30%)",
      boxShadow: "0 6px 12px rgb(75 63 79 / 30%)",
    },
    "&:focus, &.focus, &[href]:hover, &[href]:focus, &[href]:active, &[type]:hover, &[type]:focus, &[type]:active ":
      {
        // WebkitBoxShadow: "0 6px 12px rgb(75 63 79 / 30%)",
        // boxShadow: "0 6px 12px rgb(75 63 79 / 30%)",
      },
  },
  btnSecondaryOutline: {
    color: "var(--secondary)",
    backgroundColor: "var(--white)",
    borderColor: "var(--secondary)",
    borderWidth: "1px",
    "&:hover": {
      color: "var(--white)",
      backgroundColor: "var(--secondary)",
      WebkitTransform: "translateY(-2px)",
      transform: "translateY(-2px)",
      WebkitBoxShadow: "0 6px 12px rgb(75 63 79 / 30%)",
      boxShadow: "0 6px 12px rgb(75 63 79 / 30%)",
    },
    "&:focus, &.focus, &[href]:hover, &[href]:focus, &[href]:active, &[type]:hover, &[type]:focus, &[type]:active ":
      {
        // WebkitBoxShadow: "0 6px 12px rgb(75 63 79 / 30%)",
        // boxShadow: "0 6px 12px rgb(75 63 79 / 30%)",
      },
    "&[disabled]": {
      color: "var(--secondary)",
      backgroundColor: "var(--white)",
    },
  },
  btnDanger: {
    color: "var(--white)",
    backgroundColor: "var(--danger)",
    "&:hover": {
      color: "var(--white)",
      backgroundColor: "var(--danger)",
      WebkitTransform: "translateY(-2px)",
      transform: "translateY(-2px)",
      WebkitBoxShadow: "0 6px 12px rgb(251 62 46 / 30%)",
      boxShadow: "0 6px 12px rgb(251 62 46 / 30%)",
    },
    "&:focus, &.focus, &[href]:hover, &[href]:focus, &[href]:active, &[type]:hover, &[type]:focus, &[type]:active ":
      {
        // WebkitBoxShadow: "0 6px 12px rgb(251 62 46 / 30%)",
        // boxShadow: "0 6px 12px rgb(251 62 46 / 30%)",
      },
  },
  btnWarning: {
    color: "var(--white)",
    backgroundColor: "var(--warning)",
    "&:hover": {
      color: "var(--white)",
      backgroundColor: "var(--warning)",
      WebkitTransform: "translateY(-2px)",
      transform: "translateY(-2px)",
      WebkitBoxShadow: "0 6px 12px rgb(255 163 58 / 30%)",
      boxShadow: "0 6px 12px rgb(255 163 58 / 30%)",
    },
    "&:focus, &.focus, &[href]:hover, &[href]:focus, &[href]:active, &[type]:hover, &[type]:focus, &[type]:active ":
      {
        // WebkitBoxShadow: "0 6px 12px rgb(255 163 58 / 30%)",
        // boxShadow: "0 6px 12px rgb(255 163 58 / 30%)",
      },
  },
  btnInfo: {
    color: "var(--white)",
    backgroundColor: "var(--info)",
    "&:hover": {
      color: "var(--white)",
      backgroundColor: "var(--info)",
      WebkitTransform: "translateY(-2px)",
      transform: "translateY(-2px)",
      WebkitBoxShadow: "0 6px 12px rgb(75 140 237 / 30%)",
      boxShadow: "0 6px 12px rgb(75 140 237 / 30%)",
    },
    "&:focus, &.focus, &[href]:hover, &[href]:focus, &[href]:active, &[type]:hover, &[type]:focus, &[type]:active ":
      {
        // WebkitBoxShadow: "0 6px 12px rgb(75 140 237 / 30%)",
        // boxShadow: "0 6px 12px rgb(75 140 237 / 30%)",
      },
  },
  btnLight: {
    color: "var(--darkMore)",
    backgroundColor: "var(--light)",
    "&:hover": {
      color: "var(--darkMore)",
      backgroundColor: "var(--light)",
      WebkitTransform: "translateY(-2px)",
      transform: "translateY(-2px)",
      WebkitBoxShadow: "0 6px 12px rgb(75 63 79 / 30%)",
      boxShadow: "0 6px 12px rgb(75 63 79 / 30%)",
    },
    "&:focus, &.focus, &[href]:hover, &[href]:focus, &[href]:active, &[type]:hover, &[type]:focus, &[type]:active ":
      {
        // WebkitBoxShadow: "0 6px 12px rgb(75 63 79 / 30%)",
        // boxShadow: "0 6px 12px rgb(75 63 79 / 30%)",
      },
  },
  btnWhite: {
    color: "var(--darkMore)",
    backgroundColor: "var(--white)",
    "&:hover": {
      color: "var(--darkMore)",
      backgroundColor: "var(--white)",
      WebkitTransform: "translateY(-2px)",
      transform: "translateY(-2px)",
      WebkitBoxShadow: "0 6px 12px rgb(75 63 79 / 30%)",
      boxShadow: "0 6px 12px rgb(75 63 79 / 30%)",
    },
    "&:focus, &.focus, &[href]:hover, &[href]:focus, &[href]:active, &[type]:hover, &[type]:focus, &[type]:active ":
      {
        // WebkitBoxShadow: "0 6px 12px rgb(75 63 79 / 30%)",
        // boxShadow: "0 6px 12px rgb(75 63 79 / 30%)",
      },
  },
  btnDark: {
    color: "var(--white)",
    backgroundColor: "var(--darkMore)",
    "&:hover": {
      color: "var(--white)",
      backgroundColor: "var(--darkMore)",
      WebkitTransform: "translateY(-2px)",
      transform: "translateY(-2px)",
      WebkitBoxShadow: "0 6px 12px rgb(26 13 30 / 30%)",
      boxShadow: "0 6px 12px rgb(26 13 30 / 30%)",
    },
    "&:focus, &.focus, &[href]:hover, &[href]:focus, &[href]:active, &[type]:hover, &[type]:focus, &[type]:active ":
      {
        // WebkitBoxShadow: "0 6px 12px rgb(26 13 30 / 30%)",
        // boxShadow: "0 6px 12px rgb(26 13 30 / 30%)",
      },
  },
  btnLink: {
    padding: "0",
    textDecoration: "none",
    color: "var(--dark)",
    "&:hover": {
      color: "var(--dark)",
      textDecoration: "underline",
    },
    "&:focus, &.focus, &[href]:hover, &[href]:focus, &[href]:active, &[type]:hover, &[type]:focus, &[type]:active ":
      {
        textDecoration: "underline",
      },
  },
  btnLg: {
    padding: "0.985rem 34px",
    borderRadius: "9px",
    fontSize: "15px",
  },
  btnSm: {
    padding: "0.532rem 16px",
    borderRadius: "7px",
    fontSize: "14px",
  },
});
