import { createContext, useContext, useState } from "react";
const BasketContext = createContext();

export function useBasket() {
  return useContext(BasketContext);
}

export function BasketProvider({ children }) {
  const [itemList, setItemList] = useState([]);

  const addToItemList = (itemDetail) => {
    const existingItem = itemList.find(
      (item) => item.product.product_id === itemDetail.product_id
    );
    if (existingItem) {
      setItemList((prevItemList) =>
        prevItemList.map((item) =>
          item.product.product_id === itemDetail.product_id
            ? { ...item, qty: item.qty + 1 }
            : item
        )
      );
    } else {
      setItemList((prevItemList) => [
        ...prevItemList,
        { qty: 1, product: itemDetail },
      ]);
    }
  };

  const addToBasket = (product) => {
    addToItemList(product);
  };

  const removeFromItem = (productId) => {
    setItemList((prevItemList) =>
      prevItemList
        .map((item) =>
          item.product.product_id === productId
            ? { ...item, qty: item.qty - 1 }
            : item
        )
        .filter((item) => item.qty > 0)
    );
  };

  const removeFromBasket = (productId) => {
    setItemList((prevItemList) =>
      prevItemList.filter((item) => item.product.product_id !== productId)
    );
  };

  const value = {
    itemList,
    addToBasket,
    addToItemList,
    removeFromItem,
    removeFromBasket,
  };

  return (
    <BasketContext.Provider value={value}>{children}</BasketContext.Provider>
  );
}
