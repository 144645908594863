import { createUseStyles } from "react-jss";
import breakpoints from "assets/breakpoints";
export const useStyles = createUseStyles({
  mainTtle: {
    fontSize: "20px",
    lineHeight: "normal",
  },
  merCard: {
    borderRadius: "10px",
    border: "1px solid #f1f1f4",
    backgroundColor: "var(--white)",
    boxShadow: "rgb(140 149 159 / 2%) 0px 8px 24px 0px",
    [breakpoints.SCREEN_MD_MIN]: {
      padding: "18px",
    },
    [breakpoints.SCREEN_MD_MAX]: {
      padding: "15px",
    },
    "& .merCImg": {
      height: "70px",
      "& .ant-image": {
        width: "80px",
        height: "70px",
        overflow: "hidden",
        borderRadius: "10px",
        backgroundColor: "#fcfcfc",
        border: "1px solid #f1f1f4",
        "& .ant-image-img": {
          objectFit: "cover",
        },
      },
    },
    "& .merCText": {
      "& > h3": {
        fontSize: "18px",
      },
      "& > p": {
        fontSize: "15px",
        lineHeight: "1.3",
      },
    },
    "& .merCRi": {
      whiteSpace: "nowrap",
      [breakpoints.SCREEN_MD_MAX]: {
        borderTop: "1px solid #f1f1f4",
        paddingTop: "12px",
        marginTop: "15px",
      },

      "& .merCRTw": {
        "& svg": {
          width: "26px",
          height: "26px",
        },
      },
    },
  },
  spBox: {
    border: "1px solid #f1f1f4",
    boxShadow: "rgb(140 149 159 / 2%) 0px 8px 24px 0px",
    borderRadius: "10px",
    backgroundColor: "var(--white)",
    height: "100%",
    "& .spbImg": {
      height: "70px",
      "& > .ant-image": {
        width: "100% !important",
        height: "100%",
        borderTopLeftRadius: "6px",
        borderTopRightRadius: "6px",
        overflow: "hidden",
        "& > img": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      },
    },
    "& .spbText": {
      padding: "10px 12px",
      flex: "1",
      "& > span": {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
    },
  },
});
