export const abi = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "ReentrancyGuardReentrantCall",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "string",
        name: "merchant_name",
        type: "string",
      },
      {
        indexed: false,
        internalType: "uint64",
        name: "merchant_id",
        type: "uint64",
      },
      {
        indexed: false,
        internalType: "address",
        name: "merchant_address",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "MerchantEvent",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    name: "MerchantStat",
    outputs: [
      {
        internalType: "string",
        name: "merchant_name",
        type: "string",
      },
      {
        internalType: "uint64",
        name: "merchant_id",
        type: "uint64",
      },
      {
        internalType: "address payable",
        name: "merchant_address",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "_merchant_id",
        type: "uint64",
      },
    ],
    name: "deleteMerchant",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "_merchant_id",
        type: "uint64",
      },
    ],
    name: "getMerchant",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "_merchant_id",
        type: "uint64",
      },
    ],
    name: "getTransactionHash",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "_merchant_id",
        type: "uint64",
      },
    ],
    name: "isMerchantExists",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint64",
        name: "_merchant_id",
        type: "uint64",
      },
      {
        internalType: "string",
        name: "txHash",
        type: "string",
      },
    ],
    name: "storeTransactionHash",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_merchant_name",
        type: "string",
      },
      {
        internalType: "uint64",
        name: "_merchant_id",
        type: "uint64",
      },
      {
        internalType: "address payable",
        name: "_merchant_address",
        type: "address",
      },
    ],
    name: "transaction",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
] as const;
