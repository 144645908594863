import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BackIcon, CopyIcon, PrintIcon, SuccessLoader } from "svgIcon";
import {
  checkCoin,
  copyContent,
  decodeContent,
  extractID,
  shortenAddress,
  ChainCKTestBTC,
  toastMessage,
} from "utils/helper";
import { useStyles } from "components/Checkout/CompeletedOrderComponent/style";
import classNames from "classnames";
import { Col, Row } from "antd";
import ButtonComponent from "components/Forms/ButtonComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPrint } from "@fortawesome/free-solid-svg-icons";

const CompeletedOrderComponent = ({
  completedContent,
  businessContent,
}: any) => {
  const [url, setUrl] = useState("");
  const history = useHistory();
  const classes = useStyles();

  const onRenderAddress = async (getDigest: any) => {
    const renderAddress = await checkCoin(completedContent.coin, getDigest);
    if (renderAddress) {
      setUrl(renderAddress);
    }
  };

  const renderLink = (getName: any) => {
    if (getName === "POS") {
      history.push(`/pos/${businessContent.pos_domain_name}`);
    } else {
      window.open("https://dashboard.pocketpay.finance/", "_blank");
    }
  };

  useEffect(() => {
    document.body.classList.add("processPay");
    try {
      const input = window.location.pathname;
      const modifiedInput = input.replace("/checkout/", "");
      const decryptValue = decodeContent(modifiedInput);
      const result = extractID(decryptValue);
      if (result) {
        const { name, id } = result;
        if (name === "pos") {
          onRenderAddress(completedContent.digest);
        } else if (name === "paylink") {
          onRenderAddress(completedContent.digest);
        } else {
          // setErrorMessage("something went wrong");
        }
      }
    } catch (error) {
      console.log("Something went wrong");
    }
  }, [completedContent]);

  return (
    <Row
      gutter={{ xs: 10, sm: 10, md: 14, lg: 20 }}
      className="my-4"
      justify="center"
    >
      <Col
        span={14}
        xs={{ span: 24 }}
        sm={{ span: 22 }}
        md={{ span: 18 }}
        lg={{ span: 14 }}
      >
        <div className={classNames(classes.merCard, "merCardCommon")}>
          <div className="mt-4 mb-5 text-center">
            <SuccessLoader />
            <h2 className="text-success m-0">
              Your order has been placed <br />
              successfully
            </h2>
          </div>
          <div className={classNames(classes.lineList)}>
            <div className="llItems d-flex flex-wrap justify-content-between">
              <p className="m-0">Merchant name</p>
              <h3 className="m-0">{completedContent.business_name}</h3>
            </div>
            <div className="llItems d-flex flex-wrap justify-content-between">
              <p className="m-0">Amount</p>
              <h3 className="m-0">{completedContent.coin_amount}</h3>
            </div>
            <div className="llItems d-flex flex-wrap justify-content-between">
              <p className="m-0">Order ID</p>
              <h3 className="m-0">{completedContent.payment_id}</h3>
            </div>
            <div className="llItems d-flex flex-wrap justify-content-between">
              <p className="m-0">TxnID</p>
              {completedContent.coin === ChainCKTestBTC ? (
                <h3
                  className="m-0"
                  onClick={() =>
                    toastMessage("CKTESTBTC Testnet explorer is not available.")
                  }
                >
                  <span onClick={() => window.open(url, "_blank")}>
                    {shortenAddress(completedContent.digest)}
                  </span>
                  <span
                    onClick={() => copyContent(url)}
                    className="cursorPointer d-inline-block ms-2"
                  >
                    <CopyIcon />
                  </span>
                </h3>
              ) : (
                <h3 className="m-0">
                  <span onClick={() => window.open(url, "_blank")}>
                    {shortenAddress(completedContent.digest)}
                  </span>
                  <span
                    onClick={() => copyContent(url)}
                    className="cursorPointer d-inline-block ms-2"
                  >
                    <CopyIcon />
                  </span>
                </h3>
              )}
            </div>
          </div>
        </div>
        <div className="mt-3 d-flex flex-wrap align-items-center justify-content-between">
          <ButtonComponent
            btnLink
            onClick={() => renderLink(completedContent.order_type)}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="me-2" /> Back to main
          </ButtonComponent>
          <ButtonComponent
            btnPrimary
            onClick={() => toastMessage("comming soon")}
          >
            <FontAwesomeIcon icon={faPrint} className="me-2" /> Print
          </ButtonComponent>
        </div>
      </Col>
    </Row>
  );
};

export default CompeletedOrderComponent;
