import { getMerchantPosSetting } from "apiServices/app";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toastMessage, useGlobalMerchant } from "utils/helper";

const MerchantProfileComponent = ({ setProfileContent }: any) => {
  const managePathname = window.location.pathname;
  const [profileText, setProfileText] = useState("");
  const { setGlobalMerchantId } = useGlobalMerchant();
  const history = useHistory();

  const getMerchantDetails = (getUserKey: any) => {
    try {
      getMerchantPosSetting(getUserKey)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData && responseData.success == false) {
            toastMessage("No settings found for this merchant");
            setProfileText("You dont have any profile");
            history.push("/");
          } else if (responseData !== null && responseData.success !== false) {
            setProfileText("");
            setProfileContent(responseData);
            setGlobalMerchantId(responseData.merchant_id);
            localStorage.setItem("ec-email", responseData.merchant_email);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error: any) {}
  };

  useEffect(() => {
    try {
      const pathname = window.location.pathname;
      const modifiedInput = pathname.replace("/pos/", "");
      getMerchantDetails(modifiedInput);
    } catch (error) {
      console.log("Something went wrong");
    }
  }, [managePathname]);

  return <></>;
};

export default MerchantProfileComponent;
