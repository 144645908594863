import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  payCard: {
    padding: "10px 14px",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    border: "1px solid #f1f1f4",
    "& .paycItems": {
      "&:not(:last-child)": {
        marginBottom: "2px",
      },
      "& > small": {
        fontSize: "14px",
      },
      "& > span": {
        fontSize: "16px",
      },
      "&.payHead": {
        borderBottom: "1px solid #f1f1f4",
        paddingBottom: "8px",
        marginBottom: "",
      },
      "&.payTotal": {
        "& > small, & > span": {
          fontSize: "18px",
        },
      },
    },
  },
  pcardSkeleton: {
    "& .ant-skeleton": {
      width: "100%",
      border: "1px solid transparent",
      "& .ant-skeleton-input": {
        height: "140px",
        width: "100%",
        minWidth: "100%",
        borderRadius: "8px",
      },
    },
  },
});
