import BlastWalletConnect from "components/ConnectToWallet/blast";
import { useAccount } from "wagmi";
import React, { useEffect } from "react";

const BlastWalletComponent = ({
  isLoading,
  generateJsonContent,
  setLoading,
  setManageLabel,
  onPaymentSuccess,
}: any) => {
  const wallet = useAccount();

  useEffect(() => {
    if (isLoading) {
      setManageLabel("Process to connecting...");
    } else if (wallet && wallet.address !== undefined) {
      setManageLabel("Wallet connected, Pay now");
    } else {
      setManageLabel("Connect your wallet to make a payment");
    }
  }, [wallet, isLoading]);

  return (
    <BlastWalletConnect
      setLoading={setLoading}
      onPaymentSuccess={onPaymentSuccess}
      generateJsonContent={generateJsonContent}
    />
  );
};

export default BlastWalletComponent;
