import React, { useEffect } from "react";
import classNames from "classnames";
import {
  ChainBNB,
  ChainBase,
  ChainBlast,
  ChainLinea,
  ChainPolygon,
  ChainSOL,
  ChainSUI,
  ChainSolana,
  ChainSolanaBONK,
  ChainSolanaJUP,
  ChainSolanaPYTH,
  ChainSolanaUSDC,
  ChainSolanaUSDT,
  ChainSolanaWEN,
  ChainTaiko,
  selectedChainIcon,
  shortenValue,
} from "utils/helper";
import { useStyles } from "./style";
import EmptyComponent from "components/EmptyComponent";

const CoinSelectionComponent = ({
  selectedChain,
  orderPrice,
  chainPrice,
  confirmCoin,
  chainContent,
  runTimeSelectedName,
  setConfirmCoin,
  setChainName,
  setChainContent,
  setRunTimeSelectedChain,
  setShowConversion,
}: any) => {
  const classes = useStyles();
  const hasSubCoins =
    selectedChain &&
    selectedChain.sub_coin &&
    selectedChain.sub_coin.length > 0;

  const coinPriceMap: { [key: string]: any } = {
    [ChainSOL]: {
      price: chainPrice.solana,
    },
    [ChainSolanaUSDC]: {
      price: "1",
    },
    [ChainSolanaUSDT]: {
      price: chainPrice.usdt,
    },
    [ChainSolanaPYTH]: {
      price: chainPrice.pyth,
    },
    [ChainSolanaBONK]: {
      price: chainPrice.bonk,
    },
    [ChainSolanaWEN]: {
      price: chainPrice.wen,
    },
    [ChainSolanaJUP]: {
      price: chainPrice.jup,
    },
    [ChainSUI]: {
      price: chainPrice.sui,
    },
    [ChainBlast]: {
      price: chainPrice.blast,
    },
    [ChainBNB]: {
      price: chainPrice.bnb,
    },
    [ChainPolygon]: {
      price: chainPrice.polygon,
    },
    [ChainBase]: {
      price: chainPrice.base,
    },
    [ChainLinea]: {
      price: chainPrice.linea,
    },
    [ChainTaiko]: {
      price: chainPrice.taiko,
    },
    [ChainSolana]: {
      price: chainPrice.solana,
    },
  };

  const manageSubCoinValues = (getCoinName: any) => {
    if (getCoinName) {
      const coinInfo = coinPriceMap[getCoinName];
      if (coinInfo) {
        const { price } = coinInfo;
        const selectedChainFormula = `(${orderPrice} / ${price} = ${shortenValue(
          orderPrice / price
        )})`;
        return setChainContent({
          selectedChainPrice: price,
          SelectedChainAmount: shortenValue(orderPrice / price),
          selectedChainFormula,
        });
      }
    }
  };

  const manageMainCoinValues = (getCoinName: any) => {
    if (getCoinName) {
      const coinInfo = coinPriceMap[getCoinName];
      if (coinInfo) {
        const { price } = coinInfo;
        const selectedChainFormula = `(${orderPrice} / ${price} = ${shortenValue(
          orderPrice / price
        )})`;
        return setChainContent({
          selectedChainPrice: price,
          SelectedChainAmount: shortenValue(orderPrice / price),
          selectedChainFormula,
        });
      }
    }
  };

  const subCoinRender = (getName: any) => {
    const coinRenderMap: { [key: string]: JSX.Element } = {
      [ChainSOL]: <span className="extraRate">{chainPrice.solana}</span>,
      [ChainSolanaUSDC]: <span className="extraRate">$ {1}</span>,
      [ChainSolanaUSDT]: <span className="extraRate">{chainPrice.usdt}</span>,
      [ChainSolanaPYTH]: <span className="extraRate">{chainPrice.pyth}</span>,
      [ChainSolanaBONK]: <span className="extraRate">{chainPrice.bonk}</span>,
      [ChainSolanaWEN]: <span className="extraRate">{chainPrice.wen}</span>,
      [ChainSolanaJUP]: <span className="extraRate">{chainPrice.jup}</span>,
      default: (
        <span className="extraRate">
          {`$ ${chainContent.selectedChainPrice}`}
        </span>
      ),
    };

    return coinRenderMap[getName] || coinRenderMap.default;
  };

  useEffect(() => {
    const subCoinNames = [
      ChainSOL,
      ChainSolanaUSDC,
      ChainSolanaUSDT,
      ChainSolanaPYTH,
      ChainSolanaBONK,
      ChainSolanaWEN,
      ChainSolanaJUP,
    ];
    if (subCoinNames.includes(runTimeSelectedName)) {
      manageSubCoinValues(runTimeSelectedName);
    }
  }, [runTimeSelectedName]);

  useEffect(() => {
    setConfirmCoin({});
    setShowConversion(false);
    manageMainCoinValues(selectedChain && selectedChain.blockchain_name);
    setRunTimeSelectedChain(selectedChain && selectedChain.blockchain_name);
  }, [selectedChain]);

  return (
    <>
      {Object.keys(selectedChain).length > 0 ? (
        <div className={classNames(classes.coinList, "d-flex flex-wrap mb-3")}>
          {!hasSubCoins && selectedChain && (
            <div
              className={
                Object.keys(confirmCoin).length > 0 &&
                  confirmCoin.blockchain_name === runTimeSelectedName
                  ? "colItems d-flex align-items-center cursorPointer active"
                  : "colItems d-flex align-items-center cursorPointer"
              }
              onClick={() => {
                setShowConversion(true);
                setChainName(selectedChain.blockchain_name);
                setRunTimeSelectedChain(selectedChain.blockchain_name);
                setConfirmCoin(selectedChain);
              }}
            >
              <div className="coIcn d-flex align-items-center justify-content-center">
                {selectedChainIcon(selectedChain.blockchain_name)}
              </div>
              <div className="coName">
                <p className="m-0 fontWeight700">
                  {selectedChain.blockchain_name}
                </p>
                <span className="fontWeight500 d-block">
                  {chainContent.selectedChainPrice === "-"
                    ? chainContent.selectedChainPrice
                    : `$ ${chainContent.selectedChainPrice}`}
                </span>
              </div>
            </div>
          )}
          {hasSubCoins &&
            selectedChain.sub_coin.map(
              (subCoin: any) =>
                subCoin.is_active === 1 && (
                  <div
                    key={subCoin.id}
                    className={
                      Object.keys(confirmCoin).length > 0 &&
                        subCoin.blockchain_name === runTimeSelectedName
                        ? "colItems d-flex align-items-center cursorPointer active"
                        : "colItems d-flex align-items-center cursorPointer"
                    }
                    onClick={() => {
                      setShowConversion(true);
                      setChainName(subCoin.blockchain_name);
                      setRunTimeSelectedChain(subCoin.blockchain_name);
                      setConfirmCoin(subCoin);
                    }}
                  >
                    <div className="coIcn d-flex align-items-center justify-content-center">
                      {selectedChainIcon(subCoin.blockchain_name)}
                    </div>
                    <div className="coName">
                      <p className="m-0 fontWeight700">
                        {subCoin.blockchain_name}
                      </p>
                      <span className="fontWeight500 d-block">
                        {subCoinRender(subCoin.blockchain_name)}
                      </span>
                    </div>
                  </div>
                )
            )}
        </div>
      ) : (
        <div
          className={classNames(classes.coinSkeleton, "d-flex flex-wrap mb-3")}
        >
          <EmptyComponent />
          <EmptyComponent />
        </div>
      )}
    </>
  );
};

export default CoinSelectionComponent;
