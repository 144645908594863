import classNames from "classnames";
import EmptyComponent from "components/EmptyComponent";
import { useStyles } from "./style";

const CoinContentComponent = ({
  selectedChain,
  runTimeSelectedName,
  showConversion,
  orderPrice,
  chainContent,
}: any) => {
  const classes = useStyles();
  return (
    <>
      {Object.keys(selectedChain).length > 0 && showConversion ? (
        <>
          <div className={classNames(classes.payCard, "mb-2")}>
            <div className="paycItems d-flex align-items-center justify-content-between payHead">
              <span className="text-dark fontWeight700">Conversion Rate</span>
            </div>
            <div className="paycItems d-flex align-items-center justify-content-between">
              <small className="text-dark fontWeight500">Order Price</small>
              <span className="text-dark fontWeight700">$ {orderPrice}</span>
            </div>
            <div className="paycItems d-flex align-items-center justify-content-between">
              <small className="text-dark fontWeight500">
                {runTimeSelectedName} Price
              </small>
              <span className="text-dark fontWeight700">
                $ {chainContent.selectedChainPrice}
              </span>
            </div>
            <div className="paycItems d-flex align-items-center justify-content-between payTotal">
              <small className="text-dark fontWeight700">Total</small>
              <span className="text-dark fontWeight700">
                {chainContent.SelectedChainAmount}
              </span>
              {/* <span className="contentFormula">
                {chainContent.selectedChainFormula}
              </span> */}
            </div>
          </div>
          <div className={classNames(classes.payCard)}>
            <div className="paycItems d-flex align-items-center justify-content-between payHead">
              <span className="text-dark fontWeight700">Order Summary</span>
            </div>
            <div className="paycItems d-flex align-items-center justify-content-between">
              <small className="text-dark fontWeight500">Order Total</small>
              <span className="text-dark fontWeight700">
                {chainContent.SelectedChainAmount}
              </span>
            </div>
            <div className="paycItems d-flex align-items-center justify-content-between">
              <small className="text-dark fontWeight500">Gas Fee</small>
              <span className="text-dark fontWeight700">-</span>
            </div>
            <div className="paycItems d-flex align-items-center justify-content-between payTotal">
              <small className="text-dark fontWeight700">Total</small>
              <span className="text-dark fontWeight700">
                {chainContent.SelectedChainAmount} {runTimeSelectedName}
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classNames(classes.pcardSkeleton, "mb-2")}>
            <EmptyComponent />
          </div>
          <div className={classNames(classes.pcardSkeleton)}>
            <EmptyComponent />
          </div>
        </>
      )}
    </>
  );
};

export default CoinContentComponent;
