import { ReactNode, createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import axios from "axios";
import { Image } from "antd";
import { EvmPriceServiceConnection } from "@pythnetwork/pyth-evm-js";
import BonkIcon from "assets/images/solana_bonk.svg";
import WenIcon from "assets/images/solana_wen.svg";
import {
  BinanceNewIcon,
  BlastIcon,
  PolygonIcon,
  SolanaIcon,
  SolanaUsdcIcon,
  SuiIcon,
  PythIcon,
  JupIcon,
  UsdtIcon,
  BaseIcon,
  LineaIcon,
  TaikoIcon,
} from "svgIcon";

class PriceFeed {
  private _emaPrice: {
    conf: string;
    expo: number;
    price: string;
    publishTime: number;
  };
  constructor(data: any) {
    this._emaPrice = data.emaPrice;
  }
  get emaPrice(): {
    conf: string;
    expo: number;
    price: string;
    publishTime: number;
  } {
    return this._emaPrice;
  }
}

export const ChainSUI = "SUI";
export const ChainBlast = "Blast";
export const ChainBase = "BASE";
export const ChainLinea = "LINEA";
export const ChainTaiko = "TAIKO";
export const ChainAptos = "Aptos";
export const ChainVenom = "Venom";
export const ChainPolygon = "Polygon";
export const ChainBNB = "BNB";
export const ChainEVM = "EVM";
export const ChainSolana = "Solana";
export const ChainSolanaUSDC = "SOL-USDC";
export const ChainSOL = "SOL";
export const ChainSolanaUSDT = "USDT";
export const ChainSolanaPYTH = "PYTH";
export const ChainSolanaBONK = "BONK";
export const ChainSolanaWEN = "WEN";
export const ChainSolanaJUP = "JUP";
export const ChainICP = "ICP";
export const ChainCKBTC = "ckBTC";
export const ChainCKTestBTC = "ckTESTBTC";

export const setStorageData = (key: any, data: any) => {
  // const dataTemp = JSON.stringify(data);
  localStorage.setItem(key, data);
};

export const getStorageData = (key: string) => {
  try {
    const item = localStorage.getItem(key);
    if (item !== null) {
      return item;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export function extractID(str: any) {
  const match = str.match(/(pos|paylink)=(\d+)/);
  if (match) {
    const [, name, id] = match; // Destructuring the matched array
    return { name, id };
  }
  return null;
}

export const decodeContent = (pos: string) => {
  return atob(pos); // Use btoa to base64 encode the string
};

export const encodeContent = (pos: string) => {
  return btoa(pos);
};

export function shortenValue(value: any): any {
  return parseFloat(value.toFixed(6));
}

export const dateAndTimeFormat = () => {
  const timestamp = Date.now();
  const humanReadableDate = new Date(timestamp).toLocaleString("sv-SE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
  return humanReadableDate;
};

export const copyContent = (textToCopy: string) => {
  copy(textToCopy, {
    debug: true,
    message: "Press #{key} to copy",
  });
  toastMessage("Copied!");
};

export const selectedChainKey = (getCoinName: any) => {
  switch (getCoinName) {
    case "BASE": {
      return 84532;
    }
    case "BNB": {
      return 97;
    }
    case "Blast": {
      return 168587773;
    }
    case "LINEA": {
      return 59141;
    }
    case "Polygon": {
      return 80001;
    }
    case "TAIKO": {
      return 167008;
    }
  }
};

export const solTokenAddresses = (getCoinName: any) => {
  switch (getCoinName) {
    case "SOL-USDC": {
      return "Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr"; // Devnet USDC
    }
    // case "SOL-USDC": {
    //   return "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"; // Mainnet USDC
    // }
    case "USDT": {
      return "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB";
    }
    case "JUP": {
      return "JUPyiwrYJFskUPiHa7hkeR8VUtAeFoSYbKedZNsDvCN";
    }
    case "PYTH": {
      return "HZ1JovNiVvGrGNiiYvEozEVgZ58xaU3RKwX8eACQBCt3";
    }
    case "BONK": {
      return "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263";
    }
    case "WEN": {
      return "WENWENvqqNya429ubCdR81ZmD69brwQaaBYY6p3LCpk";
    }
  }
};

export const selectedChainIcon = (getCoinName: any) => {
  switch (getCoinName) {
    case "Blast": {
      return <BlastIcon />;
    }
    case "BASE": {
      return <BaseIcon />;
    }
    case "LINEA": {
      return <LineaIcon />;
    }
    case "TAIKO": {
      return <TaikoIcon />;
    }
    case "SUI": {
      return <SuiIcon />;
    }
    case "Solana": {
      return <SolanaIcon />;
    }
    case "SOL": {
      return <SolanaIcon />;
    }
    case "SOL-USDC": {
      return <SolanaUsdcIcon />;
    }
    case "USDT": {
      return <UsdtIcon />;
    }
    case "JUP": {
      return <JupIcon />;
    }
    case "PYTH": {
      return <PythIcon />;
    }
    case "BONK": {
      return <Image src={BonkIcon} preview={false} alt="bonk icon" />;
    }
    case "WEN": {
      return <Image src={WenIcon} preview={false} alt="wen icon" />;
    }
    case "BNB": {
      return <BinanceNewIcon />;
    }
    case "Polygon": {
      return <PolygonIcon />;
    }
  }
};

export const identifyCoinNetwork = (getCoinName: any) => {
  switch (getCoinName) {
    case "SUI": {
      return "Testnet";
    }
    case "Blast": {
      return "Testnet";
    }
    case "BNB": {
      return "Testnet";
    }
    case "Polygon": {
      return "Testnet";
    }
    case "TAIKO": {
      return "Testnet";
    }
    case "BASE": {
      return "Testnet";
    }
    case "LINEA": {
      return "Testnet";
    }
    case "Solana": {
      return "Devnet"; // change to Devnet don't forget to do on mainnet
    }
    case "SOL": {
      return "Devnet"; // change to Devnet don't forget to do on mainnet
    }
    case "SOL-USDC": {
      return "Devnet"; // change to Devnet don't forget to do on mainnet
    }
    case "USDT": {
      return "Mainnet";
    }
    case "PYTH": {
      return "Mainnet";
    }
    case "BONK": {
      return "Mainnet";
    }
    case "WEN": {
      return "Mainnet";
    }
    case "JUP": {
      return "Mainnet";
    }
    default: {
      return "Mainnet";
    }
  }
};

export const checkCoin = (getCoinName: any, getDigest: any) => {
  switch (getCoinName) {
    case "SUI": {
      return `https://suiexplorer.com/txblock/${getDigest}?network=testnet`;
    }
    case "Blast": {
      return `https://testnet.blastscan.io/tx/${getDigest}`;
    }
    case "SOL": {
      return `https://solscan.io/tx/${getDigest}`;
    }
    case "SOL-USDC": {
      return `https://solscan.io/tx/${getDigest}`;
    }
    case "USDT": {
      return `https://solscan.io/tx/${getDigest}`;
    }
    case "PYTH": {
      return `https://solscan.io/tx/${getDigest}`;
    }
    case "BONK": {
      return `https://solscan.io/tx/${getDigest}`;
    }
    case "WEN": {
      return `https://solscan.io/tx/${getDigest}`;
    }
    case "JUP": {
      return `https://solscan.io/tx/${getDigest}`;
    }
    case "Polygon": {
      return `https://mumbai.polygonscan.com/tx/${getDigest}`;
    }
    case "BASE": {
      return `https://sepolia.basescan.org/tx/${getDigest}`;
    }
    case "LINEA": {
      return `https://sepolia.lineascan.build/tx/${getDigest}`;
    }
    case "TAIKO": {
      return `https://katla.taikoscan.network/tx/${getDigest}`;
    }
    case "BNB": {
      return `https://testnet.bscscan.com/tx/${getDigest}`;
    }
  }
};

const getTokenSolAmount = async (setManageChainAmount: any) => {
  const requests = [
    axios.get("https://price.jup.ag/v4/price?ids=USDT"),
    axios.get("https://price.jup.ag/v4/price?ids=PYTH"),
    axios.get("https://price.jup.ag/v4/price?ids=WEN"),
    axios.get("https://price.jup.ag/v4/price?ids=JUP"),
  ];
  const responses = await Promise.all(requests);
  responses.forEach((response, index) => {
    const { data } = response.data;
    const tokenSymbol = Object.keys(data)[0];
    const amount = data[tokenSymbol]?.price || 0;
    switch (index) {
      case 0:
        setManageChainAmount((prevAmount: any) => ({
          ...prevAmount,
          usdt: amount,
        }));
        break;
      case 1:
        setManageChainAmount((prevAmount: any) => ({
          ...prevAmount,
          pyth: amount,
        }));
        break;
      case 2:
        setManageChainAmount((prevAmount: any) => ({
          ...prevAmount,
          wen: amount,
        }));
        break;
      case 3:
        setManageChainAmount((prevAmount: any) => ({
          ...prevAmount,
          jup: amount,
        }));
        break;
      default:
        break;
    }
  });
};

const formatPrice = (price: string, expo: number) => {
  const integerPart = price.substring(0, price.length + expo);
  const decimalPart = price.substring(price.length + expo);
  const formattedPrice = `${integerPart}.${decimalPart}`;
  return formattedPrice;
};

const pythSui = async (setManageChainAmount: any) => {
  const connectionEVM = new EvmPriceServiceConnection(
    "https://hermes.pyth.network"
  );
  const priceIdsEVM = [
    "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
    "0x23d7315113f5b1d3ba7a83604c44b94d79f4fd69af77f804fc7f920a6dc65744",
  ];
  const priceFeedUpdateData1 = await connectionEVM.getLatestPriceFeeds(
    priceIdsEVM
  );
  if (priceFeedUpdateData1) {
    const emaPricePrice1 = new PriceFeed(priceFeedUpdateData1[0]).emaPrice
      .price;
    const emaExpoPrice1 = new PriceFeed(priceFeedUpdateData1[0]).emaPrice.expo;
    const formattedPrice1 = formatPrice(emaPricePrice1, emaExpoPrice1);

    const emaPricePrice2 = new PriceFeed(priceFeedUpdateData1[1]).emaPrice
      .price;
    const emaExpoPrice2 = new PriceFeed(priceFeedUpdateData1[1]).emaPrice.expo;
    const formattedPrice2 = formatPrice(emaPricePrice2, emaExpoPrice2);

    if (formattedPrice1 && formattedPrice2) {
      const ethPrice = parseFloat(formattedPrice1);
      const suiPrice = parseFloat(formattedPrice2);
      setManageChainAmount((prevAmount: any) => ({
        ...prevAmount,
        blast: ethPrice,
        base: ethPrice,
        linea: ethPrice,
        taiko: ethPrice,
      }));
      setManageChainAmount((prevAmount: any) => ({
        ...prevAmount,
        sui: suiPrice,
      }));
    }
  }
};

/* https://api.coinbase.com */
export const getCoinAmount = async (setManageChainAmount: any) => {
  try {
    pythSui(setManageChainAmount);
    getTokenSolAmount(setManageChainAmount);
    const requests = [
      axios.get("https://api.coinbase.com/v2/prices/eth-USD/buy"),
      axios.get("https://api.coinbase.com/v2/prices/Sol-USD/buy"),
      axios.get("https://api.coinbase.com/v2/prices/bnb-usd/buy"),
      axios.get("https://api.coinbase.com/v2/prices/bonk-usd/buy"),
      axios.get("https://api.coinbase.com/v2/prices/matic-usd/buy"),
      axios.get("https://api.coinbase.com/v2/prices/sui-USD/buy"),
    ];
    const responses = await Promise.all(requests);
    responses.forEach((response, index) => {
      const { data } = response.data;
      const amount = data && data.amount ? parseFloat(data.amount) : 0;
      switch (index) {
        case 0:
          // setManageChainAmount((prevAmount: any) => ({
          //   ...prevAmount,
          //   blast: amount,
          //   base: amount,
          //   linea: amount,
          //   taiko: amount,
          // }));
          break;
        case 1:
          setManageChainAmount((prevAmount: any) => ({
            ...prevAmount,
            solana: amount,
          }));
          break;
        case 2:
          setManageChainAmount((prevAmount: any) => ({
            ...prevAmount,
            bnb: amount,
          }));
          break;
        case 3:
          setManageChainAmount((prevAmount: any) => ({
            ...prevAmount,
            bonk: amount,
          }));
          break;
        case 4:
          setManageChainAmount((prevAmount: any) => ({
            ...prevAmount,
            polygon: amount,
          }));
          break;
        case 5:
          // setManageChainAmount((prevAmount: any) => ({
          //   ...prevAmount,
          //   sui: amount,
          // }));
          break;
        default:
          break;
      }
    });
  } catch (error: any) { }
};

export const toastMessage = (data: any) =>
  toast(data, {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export function shortenAddress(
  text: string,
  start = 5,
  end = 3,
  separator = "..."
): string {
  return !text
    ? ""
    : text.slice(0, start) + separator + (end ? text.slice(-end) : "");
}

export const isSOLPBCoins = (getChainData: any) => {
  if (
    getChainData === "SOL" ||
    getChainData === "Solana" ||
    getChainData === "SOL-USDC" ||
    getChainData === "USDT" ||
    getChainData === "PYTH" ||
    getChainData === "BONK" ||
    getChainData === "WEN" ||
    getChainData === "JUP" ||
    getChainData === "Polygon" ||
    getChainData === "Blast" ||
    getChainData === "BNB" ||
    getChainData === "BASE" ||
    getChainData === "LINEA" ||
    getChainData === "TAIKO"
  ) {
    return true;
  } else {
    return false;
  }
};

interface GlobalMerchantContextType {
  globalMerchantId: string | null;
  setGlobalMerchantId: (merchantId: string | null) => void;
}

const GlobalMerchantContext = createContext<
  GlobalMerchantContextType | undefined
>(undefined);

export function GlobalMerchantProvider({ children }: { children: ReactNode }) {
  const [globalMerchantId, setGlobalMerchantId] = useState<string | null>(null);

  return (
    <GlobalMerchantContext.Provider
      value={{ globalMerchantId, setGlobalMerchantId }}
    >
      {children}
    </GlobalMerchantContext.Provider>
  );
}

export function useGlobalMerchant() {
  const context = useContext(GlobalMerchantContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalMerchant must be used within a GlobalMerchantProvider"
    );
  }
  return context;
}

interface GlobalDisplayContextType {
  globalDisplayId: Boolean;
  setGlobalDisplayId: (globalDisplayId: Boolean) => void;
}

const GlobalDisplayContext = createContext<
  GlobalDisplayContextType | undefined
>(undefined);

export function GlobalDisplayProvider({ children }: { children: ReactNode }) {
  const [globalDisplayId, setGlobalDisplayId] = useState<Boolean>(false);

  return (
    <GlobalDisplayContext.Provider
      value={{ globalDisplayId, setGlobalDisplayId }}
    >
      {children}
    </GlobalDisplayContext.Provider>
  );
}

export function useGlobalDisplay() {
  const context = useContext(GlobalDisplayContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalMerchant must be used within a GlobalMerchantProvider"
    );
  }
  return context;
}
