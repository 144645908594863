import React, { useState } from "react";
import classNames from "classnames";
import { PendingLoader } from "svgIcon";
import { identifyCoinNetwork } from "utils/helper";
import {
  updatePaylinkStatusWithApi,
  updatePosStatusWithApi,
} from "containers/CheckoutContainer/constant";
import SuiWalletComponent from "../SuiWalletComponent";
import BlastWalletComponent from "../BlastWalletComponent";
import { useStyles } from "./style";

const PaymentWalletComponent = ({
  amount,
  cusEmail,
  setIsPaymentSuccess,
  setManageDigest,
  generateJsonContent,
  qrContentTitle,
  merchantContent,
  setShowNextCompleted,
}: any) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [manageLabel, setManageLabel] = useState("");

  const onSuccess = (digest: any) => {
    setManageDigest(digest);
    setLoading(false);
    setShowNextCompleted(true);
    // setIsPaymentSuccess(true);
  };

  const onPaymentSuccess = (
    content: any,
    digest: any,
    sender: any,
    getKey: any
  ) => {
    // setQrStatus(5);
    if (merchantContent.order_type === "pos") {
      updatePosStatusWithApi(
        content,
        digest,
        sender,
        merchantContent,
        onSuccess,
        cusEmail
      );
    } else {
      updatePaylinkStatusWithApi(
        content,
        digest,
        sender,
        merchantContent,
        onSuccess,
        cusEmail
      );
    }
  };

  const renderChainComponent = (getChainName: any) => {
    switch (getChainName) {
      case "SUI": {
        return (
          <SuiWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "Blast": {
        return (
          <BlastWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "LINEA": {
        return (
          <BlastWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "BASE": {
        return (
          <BlastWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "TAIKO": {
        return (
          <BlastWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "Polygon": {
        return (
          <BlastWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
      case "BNB": {
        return (
          <BlastWalletComponent
            generateJsonContent={generateJsonContent}
            setLoading={setLoading}
            setManageLabel={setManageLabel}
            onPaymentSuccess={onPaymentSuccess}
          />
        );
      }
    }
  };

  return (
    <div className={classNames(classes.conWallet, "text-center")}>
      {isLoading && (
        <div className="py-5">
          <PendingLoader />
          <h4 className="mt-2">Process to connecting...</h4>
        </div>
      )}
      {!isLoading && (
        <>
          <div className="conContent">
            {manageLabel !== "" && <h3 className="mt-0 mb-4">{manageLabel}</h3>}
            <div className={classNames(classes.cainList)}>
              <div className="clItems d-flex align-items-center justify-content-center mb-1">
                <p className="m-0 text-dark fontWeight600">
                  Amount:
                  {Object.keys(generateJsonContent).length > 0 && (
                    <span
                      className="text-primary fontWeight700 mx-1"
                      style={{ position: "relative", top: "1px" }}
                    >{`${amount} ${generateJsonContent?.blockchain}`}</span>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="conBtn">
            {renderChainComponent(generateJsonContent.blockchain)}
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentWalletComponent;
