import { Col, Radio, Row } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classNames from "classnames";
import {
  ChainBNB,
  ChainBase,
  ChainBlast,
  ChainLinea,
  ChainPolygon,
  ChainSOL,
  ChainSUI,
  ChainSolanaBONK,
  ChainSolanaJUP,
  ChainSolanaPYTH,
  ChainSolanaUSDC,
  ChainSolanaUSDT,
  ChainSolanaWEN,
  ChainTaiko,
  selectedChainIcon,
  shortenValue,
  toastMessage,
} from "utils/helper";
import { SelectSVGIcn } from "svgIcon";
import { Email } from "utils/validation";
import CoinUsingComponent from "components/Checkout/PayUsingComponent/CoinUsingComponent";
import MerchantPayComponent from "components/Merchant/MerchantPayComponent";
import { useStyles } from "components/Checkout/ChainSelectionComponent/style";
import TextBoxComponent from "components/Forms/TextBoxComponent";
import ButtonComponent from "components/Forms/ButtonComponent";

const ChainSelectionComponent = ({
  contentTitle,
  businessContent,
  cusEmail,
  setCusEmail,
  setShowResPayUsing,
  isSelectChain,
  setIsSelectChain,
  setChainName,
  chainName,
  setAmount,
  amount,
  data,
  merchantContent,
  manageChainAmount,
  onGenerateQrContent,
  qrContent,
}: any) => {
  const [selectedCoin, setSelectedCoin] = useState<any>({});
  const [confirmCoin, setConfirmCoin] = useState<any>({});
  const [showInputBox, setShowInputBox] = useState(false);
  const [errors, setErrors] = useState<any>();
  const history = useHistory();
  const classes = useStyles();

  type CalculationFunction = (amount: number) => number | string;

  const calculationFunctions: Record<string, CalculationFunction> = {
    [ChainSUI]: (amount) => shortenValue(amount / manageChainAmount.sui),
    [ChainBlast]: (amount) => shortenValue(amount / manageChainAmount.blast),
    [ChainBase]: (amount) => shortenValue(amount / manageChainAmount.base),
    [ChainLinea]: (amount) => shortenValue(amount / manageChainAmount.linea),
    [ChainTaiko]: (amount) => shortenValue(amount / manageChainAmount.taiko),
    [ChainBNB]: (amount) => shortenValue(amount / manageChainAmount.bnb),
    [ChainPolygon]: (amount) =>
      shortenValue(amount / manageChainAmount.polygon),
    [ChainSOL]: (amount) => shortenValue(amount / manageChainAmount.solana),
    [ChainSolanaUSDC]: (amount) => shortenValue(amount),
    [ChainSolanaUSDT]: (amount) =>
      shortenValue(amount / manageChainAmount.usdt),
    [ChainSolanaPYTH]: (amount) =>
      shortenValue(amount / manageChainAmount.pyth),
    [ChainSolanaBONK]: (amount) =>
      shortenValue(amount / manageChainAmount.bonk),
    [ChainSolanaWEN]: (amount) => shortenValue(amount / manageChainAmount.wen),
    [ChainSolanaJUP]: (amount) => shortenValue(amount / manageChainAmount.jup),
  };

  const onPaySuccess = (getCurrentAmount: any) => {
    setAmount(getCurrentAmount);
    setIsSelectChain(true);
    onGenerateQrContent(getCurrentAmount);
  };

  const onPaynow = () => {
    const calculationFunction = calculationFunctions[chainName];
    if (calculationFunction) {
      const calculatedAmount = calculationFunction(amount);
      onPaySuccess(calculatedAmount);
    } else {
      toastMessage("Coming soon");
    }
  };

  const onVerifyPay = () => {
    if (Object.keys(selectedCoin).length === 0) {
      toastMessage("Please select Blockchain");
    } else if (Object.keys(confirmCoin).length === 0) {
      toastMessage("Please select coin");
    } else if (Object.keys(confirmCoin).length > 0) {
      if (cusEmail !== "") {
        const verifyEmail = Email(cusEmail);
        if (verifyEmail) {
          toastMessage("Please fillup email proper");
          return;
        }
      }
      if (
        merchantContent.order_type === "Paylinks" &&
        merchantContent.image_path !== null &&
        merchantContent.call_back_url !== null
      ) {
        const currentDate = new Date();
        const linkExpireTime = new Date(merchantContent.link_expire_time);
        if (currentDate < linkExpireTime) {
          onPaynow();
          setShowResPayUsing(false);
        } else {
          toastMessage("OOPS! Linked expired! You cant do the payment");
          return;
        }
      } else {
        onPaynow();
        setShowResPayUsing(false);
      }
    }
  };

  const onNextEvent = () => {
    if (Object.keys(selectedCoin).length === 0) {
      toastMessage("Please select Blockchain");
    } else {
      setShowResPayUsing(true);
    }
  };

  useEffect(() => {
    if (!isSelectChain) {
      setSelectedCoin({});
      setShowInputBox(false);
    }
  }, [isSelectChain]);

  useEffect(() => {
    document.body.classList.remove("processPay");
    if (Object.keys(confirmCoin).length > 0) {
      setShowInputBox(true);
    }
  }, [confirmCoin]);

  return (
    <>
      <div className="container bContainer sChain">
        <ToastContainer />
        <Row gutter={{ xs: 10, sm: 10, md: 14, lg: 20 }} className="bRow">
          <Col
            span={16}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 16 }}
          >
            <div className={classNames(classes.mainLe, "mainLeComon")}>
              <MerchantPayComponent
                merchantContent={merchantContent}
                contentTitle={contentTitle}
                qrContent={qrContent}
              />
              <h3 className={classNames(classes.mainTtle, "mt-0 mb-2")}>
                Select Blockchain to Pay
              </h3>
              <Radio.Group
                className={classNames(
                  classes.blockChainList,
                  "d-flex flex-wrap"
                )}
                value={chainName}
                onChange={(e) => setChainName(e.target.value)}
              >
                {data.map((item: any) => (
                  <div
                    className={
                      selectedCoin &&
                      selectedCoin.blockchain_name === item.blockchain_name
                        ? "bclItems active"
                        : "bclItems"
                    }
                    key={item.blockchain_id}
                  >
                    <Radio
                      className="bclIInner bg-white"
                      value={item.blockchain_name}
                      key={item.blockchain_name}
                      onClick={() => setSelectedCoin(item)}
                    >
                      {selectedCoin.blockchain_name === item.blockchain_name ? (
                        <SelectSVGIcn className="bclSelect" />
                      ) : (
                        ""
                      )}
                      <div className="bclIcn">
                        {selectedChainIcon(item.blockchain_name)}
                      </div>
                      <div className="bclText text-dark fontWeight800">
                        {item.blockchain_name}
                      </div>
                    </Radio>
                  </div>
                ))}
              </Radio.Group>
            </div>
          </Col>
          <Col
            span={8}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 8 }}
          >
            <div className={classNames(classes.mainRi, "mainRiCommon w-100")}>
              <h4 className="mriTitle m-0 fontWeight700 text-dark">
                Pay Using
              </h4>
              <div className="mriBody d-flex flex-column">
                <div className="mribTo">
                  <div className="mriPayEmail mb-3">
                    {merchantContent && (
                      <TextBoxComponent
                        title="Email address"
                        placeholder="Email address"
                        extraClass="mb-0"
                        name="cusEmail"
                        mandatory={true}
                        value={cusEmail}
                        error={errors && errors.cusEmail}
                        onChange={(e) => {
                          setErrors({ ...errors, ["cusEmail"]: "" });
                          setCusEmail(e.target.value);
                        }}
                      />
                    )}
                  </div>
                  <div className="mb-3">
                    <CoinUsingComponent
                      orderPrice={amount}
                      chainPrice={manageChainAmount}
                      selectedChain={selectedCoin}
                      setChainName={setChainName}
                      confirmCoin={confirmCoin}
                      setConfirmCoin={setConfirmCoin}
                    />
                  </div>
                </div>
                <div className="mribBo mt-auto pt-3">
                  <ButtonComponent
                    btnLg
                    btnPrimary
                    className="w-100"
                    title="Pay Now"
                    onClick={() => onVerifyPay()}
                  >
                    Pay Now
                  </ButtonComponent>
                  {merchantContent && merchantContent.order_type === "POS" && (
                    <ButtonComponent
                      btnLg
                      btnSecondaryOutline
                      className="mt-2 w-100"
                      title="Cancel Order"
                      onClick={() =>
                        history.push(`/pos/${businessContent.pos_domain_name}`)
                      }
                    >
                      Cancel Order
                    </ButtonComponent>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ChainSelectionComponent;
