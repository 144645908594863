import React, { useEffect, useState } from "react";
import ProductListComponent from "components/Product/ProductListComponent";
import MerchantProfileComponent from "components/Merchant/MerchantProfileComponent";
import { useBasket } from "containers/ProductContainer/constant";
import BasketComponent from "components/BasketComponent";
import CategoryComponent from "components/Product/Category/CategoryComponent";
import { FilterIcon } from "svgIcon";
import { Col, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getMerchantCategory, getMerchantProduct } from "apiServices/app";
import {
  toastMessage,
  useGlobalDisplay,
  useGlobalMerchant,
} from "utils/helper";
import { ToastContainer } from "react-toastify";
import { useStyles } from "./style.js";
import classNames from "classnames";
import TextBoxComponent from "components/Forms/TextBoxComponent";
import ButtonComponent from "components/Forms/ButtonComponent";

const ProductComponent = () => {
  const classes = useStyles();
  const [showLoader, setShowLoader] = useState(false);
  const [profileContent, setProfileContent] = useState<any>({});
  const [showSideCategories, setShowSideCategories] = useState(false);
  const [showSideBasket, setShowSideBasket] = useState(false);
  const { globalMerchantId } = useGlobalMerchant();
  const { globalDisplayId } = useGlobalDisplay();
  const [newOne, setNewOne] = useState(0);
  const itemlist = useBasket();
  const [catClick, setCatClick] = useState({
    category_id: 99001,
    category_name: "All Categories",
    image_path: null,
  });
  const [productList, setProductList] = useState<any>([]);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [screenSize, setScreenSize] = useState(window.innerWidth < 992);

  let length = 0;
  if (itemlist && itemlist.itemList) {
    length = itemlist.itemList.length;
  }

  const onMerchantCategory = (getProductList: any) => {
    try {
      getMerchantCategory(globalMerchantId)
        .then((response: any) => {
          const responseData = response.data || response;
          if (responseData !== null && responseData.success !== false) {
            if (responseData.length === 0) {
              setShowLoader(false);
              toastMessage("OOPS you dont have any category");
            } else {
              setCategoryList([
                {
                  category_id: 99001,
                  category_name: "All Categories",
                  image_path: null,
                  total_num_of_product: getProductList.length,
                },
                ...responseData.map((category: any) => {
                  // Calculate total number of products for each category
                  let totalProducts = getProductList.filter((product: any) => {
                    if (category.category_name === "All Categories") {
                      return true;
                    } else {
                      return product.categories.some(
                        (cat: any) => cat.name === category.category_name
                      );
                    }
                  }).length;

                  return {
                    ...category,
                    total_num_of_product: totalProducts,
                  };
                }),
              ]);
              setNewOne(1);
              setProductList(getProductList);
              setShowLoader(false);
            }
          }
        })
        .catch((error: any) => {
          setShowLoader(false);
          toastMessage(error);
        });
    } catch (error: any) {}
  };

  const getMerchantProducts = () => {
    setShowLoader(true);
    try {
      getMerchantProduct(globalMerchantId)
        .then((response: any) => {
          const responseData = response.data || response;
          if (responseData !== null && responseData.success !== false) {
            if (responseData.length === 0) {
              setShowLoader(false);
              toastMessage("OOPS you dont have any product");
            } else {
              onMerchantCategory(responseData);
            }
          }
        })
        .catch((error: any) => {
          setShowLoader(false);
          toastMessage(error);
        });
    } catch (error: any) {}
  };

  useEffect(() => {
    document.body.classList.remove("processPay");
    if (globalMerchantId !== null && globalMerchantId !== undefined) {
      getMerchantProducts();
    }
  }, [globalMerchantId]);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <ToastContainer />
      <MerchantProfileComponent setProfileContent={setProfileContent} />
      {showLoader ? (
        <div className={classNames(classes.loaderCenterWrapper)}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : (
        <Row gutter={{ xs: 10, sm: 10, md: 14, lg: 20 }} className="bRow">
          <Col
            span={16}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 16 }}
          >
            <div className={classNames(classes.mainLe, "mainLeCommon")}>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <h2
                  className={classNames(
                    classes.mainTtle,
                    "text-dark fontWeight700 my-0 me-2"
                  )}
                >
                  Categories
                </h2>
                <div className="d-flex align-items-center">
                  <TextBoxComponent
                    extraClass="searchIcn mb-0 me-1 me-md-2"
                    placeholder="Search here..."
                    name={"search"}
                  />
                  <ButtonComponent
                    style={{
                      maxWidth: "40px",
                      flex: "0 0 40px",
                      height: "40px",
                    }}
                    extraClass="p-2"
                    title="Filter"
                    btnPrimary
                  >
                    <FilterIcon />
                  </ButtonComponent>
                </div>
              </div>
              <CategoryComponent
                viewName={"pos"}
                profileContent={profileContent}
                categoryList={categoryList}
                setCatClick={setCatClick}
                catClick={catClick}
                showSideCategories={showSideCategories}
                setNewOne={setNewOne}
              />
              <ProductListComponent
                viewName={"pos"}
                setCatClick={setCatClick}
                productList={productList}
                catClick={catClick}
                setNewOne={setNewOne}
                newOne={newOne}
              />
            </div>
          </Col>
          <Col
            span={8}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 8 }}
          >
            <div
              className={
                globalDisplayId
                  ? classNames(
                      classes.mainRi,
                      "mainRiCommon w-100 basketDrop basketDropActive"
                    )
                  : screenSize
                  ? classNames(classes.mainRi, "mainRiCommon w-100 basketDrop")
                  : classNames(classes.mainRi, "mainRiCommon w-100")
              }
            >
              <BasketComponent showSideBasket={showSideBasket} />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ProductComponent;
