import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import CheckoutComponent from "components/Checkout/CheckoutComponent";

function CheckoutContainer() {
  useEffect(() => {
    document.documentElement.classList.add("headerDeactivate");
  }, []);
  return (
    <>
      <Helmet>
        <title>Checkout | PocketPay Payment</title>
        <meta name="description" content="MTC" />
      </Helmet>
      <CheckoutComponent />
    </>
  );
}

export default CheckoutContainer;
