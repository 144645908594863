import { createUseStyles } from "react-jss";
import breakpoints from "assets/breakpoints";
export const useStyles = createUseStyles({
  payOrder: {
    "& .poHead": {
      "& svg": {
        "& > path": {
          fill: "var(--dark)",
        },
      },
      "& h4": {
        fontSize: "22px",
      },
    },
  },
  merchantInfo: {
    borderRadius: "10px",
    border: "1px solid #f1f1f4",
    boxShadow: "rgb(140 149 159 / 2%) 0px 8px 24px 0px",
    padding: "24px",
    [breakpoints.SCREEN_MDS_MAX]: {
      paddingRight: "16px",
      paddingLeft: "16px",
    },
    "& .minRi": {
      position: "relative",
      "&:before": {
        width: "1px",
        height: "100%",
        content: '""',
        position: "absolute",
        top: "50%",
        left: "0",
        opacity: ".1",
        background:
          "linear-gradient(45deg, rgb(0 0 0 / 10%), #000, rgb(0 0 0 / 10%))",
        transform: "translateY(-50%)",
        MozTransform: "translateY(-50%)",
        WebkitTransform: "translateY(-50%)",
      },
    },
  },
  merchantAddress: {
    borderRadius: "10px",
    border: "1px solid #f1f1f4",
    boxShadow: "rgb(140 149 159 / 2%) 0px 8px 24px 0px",
    padding: "14px 18px",
    "& .meaLe": {
      overflow: "hidden",
      maxWidth: "calc(100% - 50px)",
      textOverflow: "ellipsis",
      "& > p": {
        fontSize: "15px",
      },
      "& > h4": {
        fontSize: "18px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
    },
    "& .meaRi": {
      position: "relative",
      "& svg": {
        opacity: "0.4",
        "& > path": {
          fill: "var(--dark)",
        },
      },
      "&:before": {
        content: '""',
        width: "1px",
        height: "24px",
        position: "absolute",
        top: "1px",
        left: "-18px",
        backgroundColor: "#F1F1F4",
      },
      "&:hover": {
        "& svg": {
          opacity: "0.5",
          "& > path": {
            fill: "var(--primary)",
          },
        },
      },
    },
  },
});
