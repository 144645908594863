import { instanceAxios, instanceWithoutAxios } from "./axios";

export const post = (url, data = {}) => {
  return instanceAxios.post(url, data);
};

export const postWithoutHeader = (url, data = {}) => {
  return instanceWithoutAxios.post(url, data);
};

export const get = (url, params = {}) => {
  return instanceAxios.get(encodeURI(url), { params });
};

export const getWithoutHeader = (url, params = {}) => {
  return instanceWithoutAxios.get(encodeURI(url), { params });
};

export const put = (url, data = {}) => {
  return instanceAxios.put(url, data);
};

export const putWithoutHeader = (url, data = {}) => {
  return instanceWithoutAxios.put(url, data);
};

export const deleteApi = (url, data = {}) => {
  return instanceAxios.delete(url, data);
};