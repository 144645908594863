import { createUseStyles } from "react-jss";
import breakpoints from "assets/breakpoints";
export const useStyles = createUseStyles({
  addBasketClass: {},
  mainTtle: {
    fontSize: "20px",
    lineHeight: "normal",
  },
  mainLe: {},
  mainRi: {
    position: "sticky",
    top: "10px",
    background: "var(--white)",
    borderRadius: "10px",
    border: "1px solid #f1f1f4",
    boxShadow: "rgb(140 149 159 / 2%) 0px 8px 24px 0px",
    [breakpoints.SCREEN_LG_MIN]: {
      minHeight: "100%",
      height: "calc(100% - 45px)",
      maxHeight: "calc(750px - 0px)",
    },
    "&.basketDrop": {
      display: "flex",
      flexDirection: "column",
      [breakpoints.SCREEN_LG_MAX]: {
        borderRadius: "0",
        maxWidth: "300px",
        width: "100%",
        height: "100%",
        position: "fixed",
        top: "0",
        right: "0",
        zIndex: "9",
        transform: "translateX(310px)",
        WebkitTransform: "translateX(310px)",
        transition: "all 0.3s ease-in-out",
        MozTransition: "all 0.3s ease-in-out",
        WebkitTransition: "all 0.3s ease-in-out",
      },
      "&.basketDropActive": {
        [breakpoints.SCREEN_LG_MAX]: {
          transform: "translateX(0)",
          WebkitTransform: "translateX(0)",
        },
      },
      "& .mriBody": {
        [breakpoints.SCREEN_LG_MAX]: {
          minHeight: "calc(100% - 63px)",
          padding: "14px 12px",
          overflow: "auto",
        },
      },
      "& .mriTitle": {
        [breakpoints.SCREEN_LG_MAX]: {
          fontSize: "18px",
          padding: "14px 12px",
        },
      },
      "& .mribTo": {
        [breakpoints.SCREEN_LG_MAX]: {
          // height: "100%",
          // overflow: "auto",
        },
      },
    },
    "& .mriTitle": {
      borderBottom: "1px solid #F1F1F4",
      [breakpoints.SCREEN_LG_MIN]: {
        fontSize: "20px",
        padding: "16px 18px",
      },
    },

    "& .mriBody": {
      [breakpoints.SCREEN_LG_MIN]: {
        padding: "18px",
        height: "calc(100% - 63px)",
      },
      "& .mribTo": {
        flex: "1",
      },
      "& .mribBo": {},
    },
  },

  //
  loaderCenterWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  userProfile: {
    width: "50px",
    height: "50px",
    backgroundColor: "var(--dark)",
    borderRadius: "50%",
    border: "2px solid var(--grey)",
  },
  profileComponent: {
    position: "absolute",
    top: "110px",
    right: "85px",
    maxWidth: "409px",
    width: "100%",
    backgroundColor: "var(--white)",
    borderRadius: "10px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    zIndex: 3,
  },
  profileheader: {
    maxWidth: "409px",
    width: "100%",
    height: "100px",
    backgroundColor: "var(--dark)",
    borderRadius: "10px 10px 0 0",
  },
  profileBottom: {
    padding: "0 30px",
  },
  userProfile: {
    width: "100px",
    height: "100px",
  },
  profileBodyContent: {
    marginTop: "-50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderBottom: "1px solid #F1F1F4",
    paddingBottom: "30px",
  },
});
